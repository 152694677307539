import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { enumCacheStatusLoad, enumDatagridHeaderOrder } from 'src/sites/core/enum/Enum';
import { datagridFilterDetail } from 'src/sites/core/models/system/datagridFilter';
import { datagridParam } from 'src/sites/core/models/system/datagridParam';
import { workgroup } from 'src/sites/core/models/workgroup/workgroup';
import { AlertShowService } from 'src/sites/core/services/alertShow.service';
import { DatagridService } from 'src/sites/core/services/datagrid.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { MatchService } from 'src/sites/vabourlettis/services/match.service';
import { match } from '../../models/match';
import { team } from '../../models/team';
import { CacheService } from '../../services/cache.service';
import { RankingService } from '../../services/ranking.service';
import { SubjectService } from 'src/sites/core/services/subject.service';

type AOA = any[][];

@Component({
   selector: 'app-adminMatch-list',
   templateUrl: './adminMatch-list.component.html',
   styleUrls: ['./adminMatch-list.component.scss'],
   providers: [DatePipe]
})

export class AdminMatchListComponent implements OnInit {
  @ViewChild('fileInputChampionship') public fileInputChampionship: ElementRef;
  @ViewChild('fileInputMainRanking') public fileInputMainRanking: ElementRef;
  @ViewChild('fileInputNotChampionship') public fileInputNotChampionship: ElementRef;
  @ViewChild('fileInputReferee') public fileInputReferee: ElementRef;
  @ViewChild('fileInputReserveRanking') public fileInputReserveRanking: ElementRef;
  @ViewChild('fileInputYoung') public fileInputYoung: ElementRef;

  public allMatchsCurrent: match[];
  public allMatchsCurrentCS;
  public allMatchsPreviousCS;
  public allTeamsCurrent: team[];
  public allWorkgroups: workgroup[];
  public championshipNameDisplay: string = "";
  public currentChampionshipSysId = 0;
  public data_import: AOA;
  public datagridParam: datagridParam<match>;
  public dialog: any;
  public exportFilesFromAchvbPortal: string = "";
  public exportFilesFromFvwbPortal: string = "";
  public importChampionshipDisplay: string = "";
  public importDataDescriptionDisplay: string = "";
  public importDataTitleDisplay: string = "";
  public isLoading: boolean = false;
  public previousChampionshipSysId = 0;

  constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private datagridService: DatagridService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(r => {
         if (this.isLoading) this.refresh(true);
      });
   }

  public getDatagridFilters($event) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   }

  public getDateFromYoungExcel(date: string, turn: string): Date {
      if (date != null) {
         let temp1 = date.split(' ');
         if (temp1.length == 2) {
            let temp2 = temp1[1].split('/');
            return new Date(+ temp2[1] + "/" + temp2[0] + "/" + ((turn == "1") ? this.cacheService.getCurrentChampionship().yearStarting : this.cacheService.getCurrentChampionship().yearEnding));
         }
         else
            return null;
      }
      else
         return null;
   }

  public getTeamCategoryFromExcelField(number: string): number {
      let found = 0;
      for (let cpt = 0; cpt < this.allTeamsCurrent.length; cpt++) {
         if (number.trim() != "" && this.allTeamsCurrent[cpt].teamCategory != "" && number.toUpperCase().includes("/" + this.allTeamsCurrent[cpt].teamCategory.toUpperCase())) {
            found = this.allTeamsCurrent[cpt].sysId;
            return found;
         }
      }
      return found;
   }

  public getTeamCategoryFromExcelFieldForCoupe(team1: string, team2: string): number {
      let found = 0;
      let teams = team1 + team2;
      for (let cpt = 0; cpt < this.allTeamsCurrent.length; cpt++) {
         if (teams.trim() != "" && this.allTeamsCurrent[cpt].teamCategory != "" && teams.toUpperCase().includes("(" + this.getTeamCategoryWithoutCategory(this.allTeamsCurrent[cpt].teamCategory.toUpperCase()) + ")")) {
            found = this.allTeamsCurrent[cpt].sysId;
            return found;
         }
      }
      return found;
   }

  public getTeamCategoryFromExcelFieldForRanking(teamName: string): number {
      let found = 0;
      for (let cpt = 0; cpt < this.allTeamsCurrent.length; cpt++) {
         if ((this.allTeamsCurrent[cpt].teamDisplay ?? "") != "" && teamName.trim().toUpperCase() == this.allTeamsCurrent[cpt].teamDisplay.trim().toUpperCase()) {
            found = this.allTeamsCurrent[cpt].sysId;
            return found;
         }
      }
      return found;
   }

  public getTeamCategoryFromExcelFieldForYoung(number: string): number {
      let found = 0;
      for (let cpt = 0; cpt < this.allTeamsCurrent.length; cpt++) {
         if (number.trim() != "" && this.allTeamsCurrent[cpt].teamCategory != "" && number.toUpperCase().includes(this.allTeamsCurrent[cpt].teamCategory.toUpperCase())) {
            found = this.allTeamsCurrent[cpt].sysId;
         }
      }
      return found;
   }

  public getTeamCategoryWithoutCategory(category: string): string {
      //P3BM -> P3M
      return this.frameworkService.left(category, 2) + this.frameworkService.right(category, 1);
   }

  public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh(false);
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
     }

  public refresh(reload: boolean) {
      this.isLoading = false;

      if (reload) {
         //TODO JD replace this.cacheService.reloadMatch();
      }

      let currentChampionship = this.cacheService.getAllChampionships().FirstOrDefault(j => j.isCurrent);

      //-> Set filter(s)
      let filterDetail = new datagridFilterDetail();
      filterDetail.dropdownLists = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         }));

      filterDetail.dropdownSelectedValueOfLists = currentChampionship?.sysId;
      this.currentChampionshipSysId = currentChampionship?.sysId;
      let previousChampionship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.yearStarting == (Number(currentChampionship.yearStarting) - 1).toString());
      if (previousChampionship != null)
         this.previousChampionshipSysId = previousChampionship?.sysId;
      else
         this.previousChampionshipSysId = 0;
      this.importChampionshipDisplay = this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", currentChampionship);

      // List of all matchs of the current championship
      let self = this;
      this.allMatchsCurrentCS = this.cacheService.getAllMatchs().filter(
         function (record) {
            let value = record;

            const props = "match.championship.sysId".split('.');
            for (let index = 0; index < props.length; index += 1) {
               if (props[index] === undefined) break;
               if (value[props[index]] != null)
                  value = value[props[index]];
            }
            return Number(value) == self.currentChampionshipSysId;
         }
      );

      // List of all matchs of the previous championship
      this.allMatchsPreviousCS = this.cacheService.getAllMatchs().filter(
         function (record) {
            let value = record;

            const props = "match.championship.sysId".split('.');
            for (let index = 0; index < props.length; index += 1) {
               if (props[index] === undefined) break;
               if (value[props[index]] != null)
                  value = value[props[index]];
            }
            return Number(value) == self.previousChampionshipSysId;
         }
      );

      // List of all teams of the current championship
      this.allTeamsCurrent = this.cacheService.getAllTeamsWithoutPicture();

      filterDetail.labelText = this.championshipNameDisplay + " : ";
      filterDetail.name = "championship";
      filterDetail.idName = "match.championship.sysId";

      let filters = new Map<string, datagridFilterDetail>();
      filters.set(filterDetail.name, filterDetail);
      //<- Set filter(s)

      // Display the datagrid
      this.datagridParam = new datagridParam(
         {
            hidden: !this.isLoading,
            dataSource: this.cacheService.getAllMatchs(),
            filters: null, //filters
            mainTitle: "AdminMatchsListTitle",
            headerOrder: enumDatagridHeaderOrder.ApiByDefault,
            entity: new match(),
            urlApi: "C1016",
            paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
            formTitle: "MatchTitle"
         });
      this.isLoading = true;
   }

  public translatePage() {
      //ExportFiles1FromAchvbPortal
      this.translateService.get(['ExportFiles1FromAchvbPortal'])
         .subscribe(translations => {
            this.exportFilesFromAchvbPortal = translations['ExportFiles1FromAchvbPortal'];
         });

      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });

      //ImportDataTitleDisplay
      this.translateService.get(['ImportDataTitleDisplay'])
         .subscribe(translations => {
            this.importDataTitleDisplay = translations['ImportDataTitleDisplay'];
         });

      //ImportData2DescriptionDisplay
      this.translateService.get(['ImportData3DescriptionDisplay'])
         .subscribe(translations => {
            this.importDataDescriptionDisplay = translations['ImportData3DescriptionDisplay'];
         });
   }
}