import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { enumCacheStatusLoad, enumDatagridHeaderOrder } from 'src/sites/core/enum/Enum';
import { datagridParam } from 'src/sites/core/models/system/datagridParam';
import { DatagridService } from 'src/sites/core/services/datagrid.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { sponsor } from 'src/sites/vabourlettis/models/sponsor';
import { CacheService } from '../../services/cache.service';
import { SponsorService } from '../../services/sponsor.service';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { SubjectService } from 'src/sites/core/services/subject.service';

@Component({
   selector: 'app-adminSponsor-list',
   templateUrl: './adminSponsor-list.component.html',
   styleUrls: ['./adminSponsor-list.component.scss']
})
export class AdminSponsorListComponent implements OnInit {
  public championshipNameDisplay: string = "";
  public datagridParam: datagridParam<sponsor>;
  public importDataDescriptionDisplay: string = "";
  public importDataTitleDisplay: string = "";
  public isLoading: boolean = false;

  constructor(
      private subjectService: SubjectService,
      private datagridService: DatagridService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private encryptionService: EncryptionService,
      private sponsorService: SponsorService) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(value => {
         if (this.isLoading) this.refresh();
      }
      );
   }

  public getDatagridFilters($event) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   }

  public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
     }

  public refresh() {
      this.isLoading = false;

      const queryFilters = [];
      //TODO (datatagrid) !!! : Use cache if currentchampionship else call web api
      this.sponsorService.getAllWithIncluding(queryFilters).subscribe(
         (data: encryptedValue) => {
            this.datagridParam = new datagridParam(
               {
                  hidden: !this.isLoading,
                  dataSource: this.encryptionService.decryptObject(false, data.value),
                  filters: null, //filters
                  mainTitle: "AdminSponsorsListTitle",
                  headerOrder: enumDatagridHeaderOrder.ApiByDefault,
                  entity: new sponsor(),
                  urlApi: "C1020",
                  paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
                  formTitle: "SponsorTitle"
               });
            this.isLoading = true;
         }
      );
   }

  public translatePage() {
      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });
   }
}
