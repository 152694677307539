import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SaveStatusComponent } from '../components/saveStatus/saveStatus.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
   providedIn: 'root',
})
export class SnackbarService {
   private snackBarRef: MatSnackBarRef<any> | null = null;
   private messages: string[] = [];
   private intervalId: any;
   private elapsedSeconds = 0;
   private maxSeconds = 5;

   constructor(private snackBar: MatSnackBar,
      private translateService: TranslateService
   ) { }

   startInterval() {
      this.intervalId = setInterval(() => {
        this.elapsedSeconds++;
        if (this.elapsedSeconds >= this.maxSeconds) {
          this.stopInterval();
          this.snackBarRef.dismiss(); 
        }
      }, 1000); // Check every 1 second (1000 milliseconds)
    }
  
    stopInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId); // Clear the interval
      }
    }
  
    ngOnDestroy() {
      // Ensure the interval is cleared when the component is destroyed
      this.stopInterval();
    }

   open(success: boolean | null, message: string): void {



       let icon = "done";
       let classColor = "snackbarSuccess";
       if (success == null)
       {
         icon = "warning";
         classColor = "snackbarWarning";
       }
       if (success == false)
       {
         icon = "error";
         classColor = "snackbarError";
       }

       if (message == null || message == "")
      {
         if (success)
            message = this.translateService.instant("MessageDataSavedSuccessfully");
         else
            message = this.translateService.instant("MessageDataNotSavedDueToAnError");
      }
       
      if (message != null && message != "")
      {
         if (this.elapsedSeconds >= 2)
         {
            if (!success)
               this.maxSeconds = 7;
            else
               this.maxSeconds = 5;
         }
            

         this.messages.push("<span class='material-icons " + classColor + "'>" + icon + "</span><span class='" + classColor + "'>&nbsp;" + message + "</span>");
      }



      if (this.snackBarRef) {
         this.snackBarRef.instance.data.message = this.messages.join('<br><br>');
         return;
      }
      else {
         const config: MatSnackBarConfig = {
            data: { success, message: this.messages.join('<br><br>') },
         }
         config['panelClass'] = ['snackbar', 'normal'];


         this.snackBarRef = this.snackBar.openFromComponent(SaveStatusComponent, config);
         this.startInterval();
         this.snackBarRef.afterDismissed().subscribe(() => {
            this.snackBarRef = null;
            this.messages = [];
         });
      }
   }
}

