import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe, KeyValue } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatRowDef, MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Data } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { enumCRUDType, enumDatagridHeaderOrder, enumFormAction, enumWorkgroup } from "../../enum/Enum";
import { datagridFilterDetail } from '../../models/system/datagridFilter';
import { datagridParam } from '../../models/system/datagridParam';
import { metadataModel } from '../../models/system/metadataModel';
import { metadataModelBase } from '../../models/system/metadataModelBase';
import { ModalFormParam } from '../../models/system/modalFormParam';
import { AccessRightsService } from '../../services/accessRights.service';
import { AppConfigService } from '../../services/appconfig.service';
import { DatagridService } from '../../services/datagrid.service';
import { FrameworkService } from "../../services/framework.service";
import { ModalFormComponent } from '../form/modalForm.component';

@Component({
   selector: 'app-datagrid',
   templateUrl: './datagrid.component.html',
   styleUrls: ['./datagrid.component.scss'],
   animations: [
      trigger('detailExpand', [
         state('collapsed', style({ height: '0px', minHeight: '0' })),
         state('expanded', style({ height: '*' })),
         transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
   ],
})

export class DatagridComponent implements OnInit, OnChanges, AfterViewInit {
  // Order by descending property key
  private keyAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
      return a.key < b.key ? -1 : (b.key < a.key ? 1 : 0);
   }

  // Order by descending property key
  private keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
      return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
   }

  //-> Order
  private originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
      return 0;
   }

  @Input() public datagridParam: datagridParam<any>;
  @Output() public datagridFilters: EventEmitter<Map<string, datagridFilterDetail>> = new EventEmitter();
  @ViewChild('extraRow', { read: MatRowDef }) public extraRow: any;
  @ViewChild(MatPaginator, { static: false }) public mainPaginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) public mainSort: MatSort;

  public allChampionship;
  public allFilters;
  public databaseFiltersNameDisplay;
  public dialogRef;
  public expandedElement: null;
  public form: FormGroup = new FormGroup({});
  public formAction = enumFormAction;
  public IsDatagridShouldbeRefreshedSubject: boolean;
  public isDisplayOnAMobile = (window.innerWidth <= 960);
  public isLoaded: boolean = false;
  public isNotFirstTime: boolean = false;
  public listOfColumnDetailName = [];
  public listOfColumnHeader: Data[];
  public listOfColumnHeaderName = [];
  public listOfColumnMetadata;
  public listOfColumnMetadataForDetailExpanded;
  public mainDataSource = new MatTableDataSource<any>();
  public mainDataSourceData = [];
  //set later by this.datagridParam.dataSource;
  public mainTitle: string = "";
  //set later by this.datagridParam.paginatorItemsCount;
  public pageSize = 5;
  //set later by this.datagridParam.mainTitle;
  public paginatorItemsCount = [];
  public selectedOptions = new Map<string, string>();

  constructor (
      private cacheService : CacheService,
      private appConfigService: AppConfigService,
      private translateService: TranslateService,
      private frameworkService: FrameworkService,
      public accessRightsService: AccessRightsService,
      private datagridService: DatagridService,
      private sanitizer: DomSanitizer,
      public datepipe: DatePipe,
      public dialog: MatDialog) {
   }

  @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

  public getControlType(column: metadataModel): string {
      return this.frameworkService.getControlType(column);
   }

  public getDetailRowClass(expandedElement) {
      if (expandedElement == undefined)
         return "detailRow0";
      else
         return "detailRowAuto";
   }

  public getDropdownValue(rowItems: any, column: any, metadata: metadataModelBase) {
      // rowItems = items of the row
      // column = "column name"
      // metadata = meta data of "column name"

      if (metadata.editDropdownEntityMapping == "") {
         //If editDropdownEntityMapping is NOT given
         if (metadata.editDropdownStickyValues.length != 0) {
            for (let i = 0; i < metadata.editDropdownStickyValues.length; i++) {
               if (metadata.editDropdownStickyValues[i]["value"] == rowItems[column]) {
                  if (metadata.editDropdownIsTranslateForDisplay)
                     return this.translateService.instant("Dropdown" + metadata.editDropdownStickyValues[i]["viewValue"]);
                  else
                     return rowItems[column];
               }
            }
         }
         return rowItems[column];
      }

      //If editDropdownEntityMapping is given
      if (metadata.editDropdownIsTranslateForDisplay)
         return this.frameworkService.formatWithTranslation(metadata.editDropdownFormatForDisplay, metadata.editDropdownPropertiesForDisplay, rowItems[metadata.editDropdownEntityMapping], this.translateService)
      else
         return this.frameworkService.format(metadata.editDropdownFormatForDisplay, metadata.editDropdownPropertiesForDisplay, rowItems[metadata.editDropdownEntityMapping])
   }

public getElementDetailClass(columnDetail, elementRowItems, controlType) {
   if (columnDetail == null && elementRowItems == null)
      return  "";
   else
   {
      if (controlType == 'Bool2States' || controlType == 'Bool3States')
         return "element-detailForBool";
      else 
         return "element-detail";
   }
}
  public getHeaderClass() {
      return "mat-header" + this.isDisplayOnAMobile ? " matHeaderMobile" : "";
   }

  public getHtml(value: string) {
      return this.frameworkService.replaceCrLfByBr(value ?? "");
   }

  public getIcon(formAction: enumFormAction) {
      switch (formAction) {
         case enumFormAction.Add:
            return "control_point";
         case enumFormAction.Edit:
            return "edit";
         case enumFormAction.Delete:
            return "delete_forever";
         case enumFormAction.Archive:
            return "archive";
         case enumFormAction.Unarchive:
            return "unarchive";
      }
   }

  public getIconHtml(formAction: string) {
      switch (formAction) {
         case enumFormAction[enumFormAction.Add]:
            return this.getIcon(enumFormAction.Add);
         case enumFormAction[enumFormAction.Edit]:
            return this.getIcon(enumFormAction.Edit);
         case enumFormAction[enumFormAction.Delete]:
            return this.getIcon(enumFormAction.Delete);
         case enumFormAction[enumFormAction.Archive]:
            return this.getIcon(enumFormAction.Archive);
         case enumFormAction[enumFormAction.Unarchive]:
            return this.getIcon(enumFormAction.Unarchive);
      }
   }

  public getImgURL(url: string) {
      return this.sanitizer.bypassSecurityTrustUrl(JSON.parse(url));
   }

  public getIsExpandedRowExist(): boolean {
      return this.frameworkService.getIsExpandedRowExist(this.datagridParam.entity);
   }

  public getLastColumnBasedOnAccessRights(columnSelected: string, rowItems: any, listOfColumnHeader: any, isOnMobile: boolean): boolean {
      return this.accessRightsService.getLastColumnBasedOnAccessRights(columnSelected, rowItems, listOfColumnHeader, isOnMobile);
   }

  public getPropertyByString(object, propString) {
      let value = object;

      const props = propString.split('.');
      for (let index = 0; index < props.length; index += 1) {
         if (props[index] === undefined) break;
         value = value[props[index]];
      }
      return value;
   }

  //<- Order
  public getVisibilityFromAccessRights(menu: enumFormAction): boolean {
      return this.accessRightsService.getVisibilityFromAccessRights(menu);
   }

  public ifRow(rowItems, controlType) {
      if (controlType == null)
         return false;

      if (this.isDisplayOnAMobile && controlType != 'Bool2States' && controlType != 'Bool3States')
         return rowItems != null && rowItems != '';
      else
         return true;
   }

  public menuClick(event, menu: enumFormAction, element: any) {
      switch (menu) {
         case enumFormAction.Add:
            this.showPopup(enumCRUDType.Create, menu, this.datagridParam.entity);
            break;
         case enumFormAction.Edit:
            this.showPopup(enumCRUDType.Update, menu, element);
            break;
         case enumFormAction.Delete:
            this.showPopup(enumCRUDType.Delete, menu, element);
            break;
         case enumFormAction.Archive:
            this.showPopup(enumCRUDType.Update, menu, element);
            break;
         case enumFormAction.Unarchive:
            this.showPopup(enumCRUDType.Update, menu, element);
            break;
      }
   }

  public ngAfterViewInit(): void {
      this.mainDataSource.paginator = this.mainPaginator; // For pagination
      this.mainDataSource.sort = this.mainSort; // For sort
   }

  public ngOnChanges(changes: SimpleChanges): void {
      if (this.datagridParam != null && this.datagridParam.dataSource != null) {
         this.setFilters();
         this.refresh();
      }
   }

  public ngOnInit() {
      this.getScreenSize();

      
      //TODO (Datagrid) : JD RefreshDatagrid this.mainDataSourceData should be updated !!!!
      //Souscription à l'event lorsque la cache globale a terminé son chargement


      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

  public onFilterChange() {
      this.datagridFilters.emit(this.datagridParam.filters);
   }

   public getMainHeaderClass(index: number) : string {
      if (index == 0)
         return "m-card-sub-title matMainHeader";
      else
         return "m-card-sub-title";
   }

  public refresh(): void {
      this.isLoaded = false;
      this.mainDataSourceData = this.datagridParam.dataSource;

      if (this.datagridParam.filters != null) {
         this.datagridParam.filters.forEach(f => {
            if (this.form.get("all" + f.name) != null && f.name != "" && this.datagridParam != null && this.datagridParam.filters != null && this.datagridParam.filters != undefined && this.mainDataSourceData != null) {
               let self = this;
               this.mainDataSourceData = this.mainDataSourceData?.filter(
                  function (record) {
                     let value = record;

                     const props = self.datagridParam.filters.get(f.name).idName.split('.');
                     for (let index = 0; index < props.length; index += 1) {
                        if (props[index] === undefined) break;
                        if (value[props[index]] != null)
                           value = value[props[index]];
                     }

                     return value == self.form.get("all" + f.name).value;
                  }
               );
            }
         });
      }

      let listOfAllEntriesMetadata = new Map<string, string>();
      if (this.mainDataSourceData == null) {
         this.mainDataSource.data = [];
      }
      else {
         if (this.mainDataSourceData.length > 0) {
            let allEntries = Object.entries(this.mainDataSourceData[0]);
            this.mainDataSource.data = this.mainDataSourceData;

            allEntries.forEach(([key, value]) => {
               let result = this.frameworkService.getMetadataOfPropertyName(this.datagridParam.entity, key);

               if (result != null) {
                  listOfAllEntriesMetadata[key] = result;
               }

            });
         }
         else
            this.mainDataSource.data = [];
      }

      this.mainDataSource.paginator = this.mainPaginator;
      this.mainDataSource.sort = this.mainSort;

      //-> Order the data
      switch (this.datagridParam.headerOrder) {
         case enumDatagridHeaderOrder.ApiByDefault:
            {
               this.listOfColumnHeader = Object.entries(listOfAllEntriesMetadata);
               break;
            }
         case enumDatagridHeaderOrder.AngularModel:
            {
               if (listOfAllEntriesMetadata != null) {
                  this.listOfColumnHeader = Object.entries(listOfAllEntriesMetadata).sort(function (a, b) {
                     if (a[1].orderIndex > b[1].orderIndex) {
                        return 1;
                     }
                     if (a[1].orderIndex < b[1].orderIndex) {
                        return -1;
                     }
                     return 0;
                  }).map(([key, value]) => [key, value]);
               }
               break;
            }
         case enumDatagridHeaderOrder.KeyAsc:
            {
               if (listOfAllEntriesMetadata != null) {
                  this.listOfColumnHeader = Object.entries(listOfAllEntriesMetadata).sort(function (a, b) {
                     if (a > b) {
                        return 1;
                     }
                     if (a < b) {
                        return -1;
                     }
                     return 0;
                  }).map(([key, value]) => [key, value]);
               }
               break;
            }
         case enumDatagridHeaderOrder.KeyDesc:
            {
               this.listOfColumnHeader = Object.entries(listOfAllEntriesMetadata).sort(function (a, b) {
                  if (a < b) {
                     return 1;
                  }
                  if (a > b) {
                     return -1;
                  }
                  return 0;
               }).map(([key, value]) => [key, value]);
               break;
            }
      }
      //<- Order data

      let keys = [];
      let keysInDetail = [];

      for (const [key, value] of Object.entries(this.listOfColumnHeader)) {
         if (value[1].gridColumnIsVisible != enumWorkgroup[enumWorkgroup.None]) {
            if (!value[1].grisIsInDetailExpanded)
               keys.push(value[0]);
         }
      }
      this.listOfColumnHeaderName = keys;
      this.listOfColumnDetailName = keysInDetail;

      this.mainTitle = this.datagridParam.mainTitle;
      this.paginatorItemsCount = this.datagridParam.paginatorItemsCount;

      this.listOfColumnMetadata = this.frameworkService.getMetadataGridIsVisible(this.datagridParam.entity);
      this.listOfColumnMetadataForDetailExpanded = this.frameworkService.getMetadataGridIsInDetailExpanded(this.datagridParam.entity);

      this.isLoaded = true;
   }

  public setFilters() {
      if (this.datagridParam.filters != null) {
         if (!this.isNotFirstTime) {
            this.allFilters = Array.from(this.datagridParam.filters.keys());
            this.datagridParam.filters.forEach(i => {
               this.form.addControl("all" + i.name, new FormControl(''));
               this.form.get("all" + i.name).setValue(i.dropdownSelectedValueOfLists);
            });
            this.isNotFirstTime = true;
         }
      }
   }

  public showPopup(cRUDType: enumCRUDType, formAction: enumFormAction, element: any) {
      const dialogRef = this.dialog.open(ModalFormComponent, {
         //-- Modal form parameters
         data: new ModalFormParam({
            cRUDType: cRUDType,
            action: formAction,
            columnsList: this.listOfColumnHeader,
            formTitle: this.datagridParam.formTitle,
            formIcon: this.getIcon(formAction),
            entity: this.datagridParam.entity,
            urlApi: this.datagridParam.urlApi,
            element: element
         }),
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         disableClose: true
      },
      );

      dialogRef.afterClosed().subscribe(dialogResult => {
         this.dialogRef = null;
      });
   }

  public site() {
      return this.appConfigService.getSiteName();
   }

  public translatePage() {
      //DatabaseFilters
      this.translateService.get(['DatabaseFilters'])
         .subscribe(translations => {
            this.databaseFiltersNameDisplay = translations['DatabaseFilters'];
         });
   }
}
