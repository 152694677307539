import { Component, Inject, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
   selector: 'app-saveStatus',
   templateUrl: './saveStatus.component.html',
   styleUrls: ['./saveStatus.component.scss']
})
export class SaveStatusComponent implements OnInit {
   public message: string = "Vos données ont bien été enregistrées";
   public success: boolean | null = false;

   constructor(
      @Inject(MAT_SNACK_BAR_DATA) public data: any,
      private translateService: TranslateService
   ) { }

   public getClass() {
      if (this.success == null) {
         return "snackbarWarning";
      }
      else {
         if (this.success)
            return "snackbarSuccess";
         else
            return "snackbarFailed";
      }

   }
   

   public ngOnInit() {
      // this.success = this.data[0];
      // if (this.data[1] == null) {
      //    if (this.success)
      //       this.message = this.translateService.instant("MessageDataSavedSuccessfully");
      //    else
      //       this.message = this.translateService.instant("MessageDataNotSavedDueToAnError");
      // }
      // else
      //    this.message = this.data[1];
   }
}
