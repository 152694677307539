import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { eventClub } from 'src/sites/vabourlettis/models/eventClub';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { enumActivityType, enumAvailability, enumAvailabilityReason, enumClubActivityType, enumMatchDisplayType } from '../../enum/Enum';
import { AccessRightsService } from '../../services/accessRights.service';
import { FrameworkService } from '../../services/framework.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { SubjectService } from '../../services/subject.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
   selector: 'md',
   templateUrl: './matchDetail.component.html',
   styleUrls: ['./matchDetail.component.scss']
})
export class MatchDetailComponent implements OnInit, OnChanges {
  @Input() public eventClub: eventClub;
  @Input() public isClickable: boolean = false;
  @Input() public isPresentVisible: boolean = false;
  @Input() public matchDisplayType: enumMatchDisplayType = enumMatchDisplayType.NONE;
  @Input() public visible: boolean = true;
  @Output() public onClick = new EventEmitter<string>();

  public EnumActivityType = enumActivityType;
  public EnumAvailability = enumAvailability;
  public EnumAvailabilityReason = enumAvailabilityReason;
  public address1: string = "";
  public address2: string = "";
  public addressUrl: string = "";
  public awayTeam: string = "";
  public awayTeamPos: number = -1;
  public awayTeamPosDisplay: string = "";
  public day: string = "";
  public fromTeam: string = "";
  public fromTeamPos: number = -1;
  public fromTeamPosDisplay: string = "";
  public hourPresenceBeforeAtHome: string = "";
  public hourPresenceBeforeAtHome2: string = "";
  public hourPresenceBeforeNotAtHome: string = "";
  public hourPresenceBeforeNotAtHome2: string = "";
  public isAtHome: boolean = false;
  public isClosingDay: boolean = false;
  public isCoachOrAssistantOrSupervisorConnected: boolean = false;
  public isDisplayOnAMobile: boolean = false;
  public isExtraTrainingDay: boolean = false;
  public isMatch: boolean = false;
  public isMatchFull: boolean = false;
  public isMatchResult: boolean = false;
  public isMatchStatus: boolean = false;
  public isMatchStatusDetail: boolean = false;
  public isTournament: boolean = false;
  public isTrainingDay: boolean = false;
  public isFriendlyMatch: boolean = false;
  public isVictory?: boolean = null;
  public isYoung: boolean = false;
  public isHainautCup: boolean = false;
  public match: any = null;
  public matchDetailAlignment = "matchDetailAlignLeft";
  public matchNumber: string = "";
  public matchTypeDisplay: string = "";
  public reasonDisplay: string = "";
  public referee1: string = "";
  public referee2: string = "";
  public score: string = "";
  public scoreDetail: string = "";
  public scoreReserve: string = "";
  public teamDisplay: string = "";
  public time: string = "";
  public timeOnSite: string = "";
  public timeReserve: string = "";

  constructor(
      private clipboard: Clipboard,
      private cacheService: CacheService,
      private subjectService: SubjectService, 
      private frameworkService: FrameworkService,
      public accessRightsService: AccessRightsService,
      private translateService: TranslateService,
      public storageService: StorageService) {
   }

  public getEventStyle() {
      if (this.match == null || this.isMatch) {
         return;
      }

      if (this.isMatchStatus || this.isMatchStatusDetail) {
         let eventStyle = "";
         eventStyle += "background";
         if (this.isClickable)
            eventStyle += " clickable";

         if (this.match.type == enumActivityType[enumActivityType.TOURNAMENT_DAY])
            eventStyle += " tournament";
         if (this.match.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY])
            eventStyle += " friendly";
         if (this.match.type == enumActivityType[enumActivityType.CLOSING_DAY])
            eventStyle += " closingDay";
         if (this.match.type == enumActivityType[enumActivityType.TRAINING_DAY])
            eventStyle += " trainingDay";
         if (this.match.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY])
            eventStyle += " trainingDay";
         if (this.match.type == enumActivityType[enumActivityType.HAINAUT_CUP])
            eventStyle += " cup";

         if (this.match.type == enumActivityType[enumActivityType.CHAMPIONSHIP]) {
            if (this.isAtHome)
               eventStyle += " atHome";
            else
               eventStyle += " notAtHome";
         }

         if (!this.isClosingDay) {
            if (this.eventClub.availability == enumAvailability.PRESENT) {
               if (this.match.type == enumActivityType[enumActivityType.TOURNAMENT_DAY])
                  eventStyle += " tournamentBorder";
               if (this.match.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY])
                  eventStyle += " friendlyBorder";
               if (this.match.type == enumActivityType[enumActivityType.CLOSING_DAY])
                  eventStyle += " closingDayBorder";
               if (this.match.type == enumActivityType[enumActivityType.TRAINING_DAY])
                  eventStyle += " trainingDayBorder";
               if (this.match.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY])
                  eventStyle += " trainingDayBorder";
               if (this.match.type == enumActivityType[enumActivityType.HAINAUT_CUP])
                  eventStyle += " cupBorder";
            }
            else {
               if (this.eventClub.availability == enumAvailability.GUARD)
                  eventStyle += " borderGuard";
               else
                  eventStyle += " borderNotPresent";
            }
         }
         //this.frameworkService.logInfo(eventStyle, "getEventStyle");
         return eventStyle;
      }
      else
         return "";
   }

  public initialize() {
      this.isCoachOrAssistantOrSupervisorConnected = this.frameworkService.IsMemberCoachs(this.storageService.getUserConnected()) || this.accessRightsService.isUserConnectedAdministratorOrSupervisor();

      this.match = this.eventClub.event;

      this.reasonDisplay = "";
      this.day = "";
      this.isAtHome = false;
      this.address1 = "";
      this.address2 = "";
      this.matchTypeDisplay = "";
      this.matchNumber = "";
      this.teamDisplay = "";
      this.time = "";
      this.timeReserve = "";
      this.timeOnSite = "";
      this.fromTeam = "";
      this.awayTeam = "";
      this.fromTeamPos = -1;
      this.awayTeamPos = -1;
      this.fromTeamPosDisplay = "";
      this.awayTeamPosDisplay = "";
      this.referee1 = "";
      this.referee2 = "";
      this.matchDetailAlignment = "matchDetailAlignLeft";
      this.score = "";
      this.scoreDetail = "";
      this.scoreReserve = "";
      this.isVictory = null;

      this.isTrainingDay = false;
      this.isYoung = false;
      this.isHainautCup = false;
      this.isExtraTrainingDay = false;
      this.isFriendlyMatch = false;
      this.isTournament = false;
      this.isClosingDay = this.eventClub.clubActivityType == enumClubActivityType.CLOSING;

      this.isMatchResult = (this.eventClub.clubActivityType == enumClubActivityType.CHAMPIONSHIP ||
         this.eventClub.clubActivityType == enumClubActivityType.HAINAUT_CUP ||
         this.eventClub.clubActivityType == enumClubActivityType.YOUNG) && this.matchDisplayType == enumMatchDisplayType.RESULT;
      this.isMatchFull = (this.eventClub.clubActivityType == enumClubActivityType.CHAMPIONSHIP ||
         this.eventClub.clubActivityType == enumClubActivityType.HAINAUT_CUP ||
         this.eventClub.clubActivityType == enumClubActivityType.TOURNAMENT_DAY ||
         this.eventClub.clubActivityType == enumClubActivityType.EXTRA_TRAINING_DAY ||
         this.eventClub.clubActivityType == enumClubActivityType.FRIENDLY_MATCH_DAY ||
         this.eventClub.clubActivityType == enumClubActivityType.YOUNG) && this.matchDisplayType == enumMatchDisplayType.FULL;
      this.isMatch = this.matchDisplayType == enumMatchDisplayType.NORMAL;
      this.isMatchStatusDetail = this.matchDisplayType == enumMatchDisplayType.STATUS_DETAIL;
      this.isMatchStatus = this.matchDisplayType == enumMatchDisplayType.STATUS;
      this.isExtraTrainingDay = this.eventClub.clubActivityType == enumClubActivityType.EXTRA_TRAINING_DAY;
      this.isTournament = this.eventClub.clubActivityType == enumClubActivityType.TOURNAMENT_DAY;

      if (this.match == null) {
         return;
      }

      // Vérification jour de fermeture ?
      this.isClosingDay = this.match.type == enumActivityType[enumActivityType.CLOSING_DAY];
      this.isTrainingDay = this.match.type == enumActivityType[enumActivityType.TRAINING_DAY];

      //this.frameworkService.logInfo(this.eventClub);

      if (this.eventClub.reason != "") {
         if (this.isClosingDay)
            this.reasonDisplay = this.eventClub.reason;
         else
            this.reasonDisplay = this.frameworkService.lowercaseFirstLetter(this.translateService.instant("REASON_" + this.eventClub.reason));
      }
      else
         this.reasonDisplay = "";

      if (this.isMatch || this.isMatchStatus || this.isMatchStatusDetail) {
         this.day = this.frameworkService.getDateCalendarDisplay(this.match.day);
      }

      this.isAtHome = this.match.isAtHome;
      this.matchDetailAlignment = "matchDetailAlignLeft";
      if (!this.isAtHome)
         this.matchDetailAlignment = "matchDetailAlignRight";
  
      if ( this.eventClub.clubActivityType == enumClubActivityType.TOURNAMENT_DAY)
      {
         this.address1 = this.match.buildingName;
         this.address2 = "";
         this.addressUrl = this.address2;
      }
      else
      {
         this.address1 = this.match.buildingName;
         this.address2 = (this.match.address + " " + this.match.zipCode + " " + this.match.locality).trim();

         if (this.match.long == null || this.match.lat == null)
         {
            this.addressUrl = "https://maps.google.com/?q=" + this.match.address + " " + this.match.zipCode + " " + this.match.locality;
         }
         else
         {
            if (this.address2 == "null" || this.address2 == "")
            {
               this.addressUrl = "https://www.google.com/maps?q=" + this.match.lat + "," + this.match.long;
            }
               
            else
               this.addressUrl = "https://maps.google.com/?q=" + this.address2 + "&sll=" + this.match.lat + "," + this.match.long;
         }
      }
      this.matchTypeDisplay = this.translateService.instant(this.match.type);
      this.isYoung = this.match.type == enumActivityType[enumActivityType.YOUNG];
      this.isHainautCup = this.match.type == enumActivityType[enumActivityType.HAINAUT_CUP];
      this.isFriendlyMatch = this.match.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY];
      this.matchNumber = this.match.number;
      if (this.matchNumber.startsWith("CL"))
         this.matchTypeDisplay = this.matchTypeDisplay + " " + this.translateService.instant("CHAMPIONSHIP_FINAL");
      this.teamDisplay = this.match.team?.teamDisplay;

      this.hourPresenceBeforeAtHome = this.match.team?.hourPresenceBeforeAtHome;
      this.hourPresenceBeforeNotAtHome = this.match.team?.hourPresenceBeforeNotAtHome;
      this.hourPresenceBeforeAtHome2 = this.match.team?.hourPresenceBeforeAtHome2;
      this.hourPresenceBeforeNotAtHome2 = this.match.team?.hourPresenceBeforeNotAtHome2;

      this.time = "";
      if (this.match.type == enumActivityType[enumActivityType.CHAMPIONSHIP] ||
         this.match.type == enumActivityType[enumActivityType.YOUNG] ||
         this.match.type == enumActivityType[enumActivityType.HAINAUT_CUP] ||
         this.match.type == enumActivityType[enumActivityType.EXTRA_TRAINING_DAY] ||
         this.match.type == enumActivityType[enumActivityType.FRIENDLY_MATCH_DAY] ||
         this.match.type == enumActivityType[enumActivityType.TOURNAMENT_DAY] ||
         this.match.type == enumActivityType[enumActivityType.TRAINING_DAY])
         this.time = (this.match.time.includes == "00:00") ? "" : this.match.time?.replaceAll(":", "H");
      if (!this.isTrainingDay) {
         if (!this.isYoung)
            this.timeReserve = this.frameworkService.getMatchTimeOfReserve(this.match);
         this.timeOnSite = this.frameworkService.getMatchTimeOnSite(this.match);
      }
      //this.isTrainingDay -> timeFrom - timeTo
      this.fromTeam = this.match.fromTeam;
      this.awayTeam = this.match.awayTeam;

      if (this.isMatchFull) {
         let allTeams = this.cacheService.getAllRankings().filter(i => !i.isReserve && i.teamSysId == this.match.team.sysId);
         this.fromTeamPos = -1;
         this.awayTeamPos = -1;
         this.fromTeamPosDisplay = "";
         this.awayTeamPosDisplay = "";
         if (allTeams.length > 0) {
            this.fromTeamPos = allTeams.FirstOrDefault(i => i.teamName.toUpperCase() == this.fromTeam.toUpperCase()) == null ? -1 : allTeams.FirstOrDefault(i => i.teamName.toUpperCase() == this.fromTeam.toUpperCase()).position;
            this.awayTeamPos = allTeams.FirstOrDefault(i => i.teamName.toUpperCase() == this.awayTeam.toUpperCase()) == null ? -1 : allTeams.FirstOrDefault(i => i.teamName.toUpperCase() == this.awayTeam.toUpperCase()).position;
         }

         if (this.fromTeamPos != -1 && this.awayTeamPos != -1) {
            this.fromTeamPosDisplay = (this.fromTeamPos == 1) ? "Premier" : this.fromTeamPos + "ème";
            this.awayTeamPosDisplay = (this.awayTeamPos == 1) ? "premier" : this.awayTeamPos + "ème";
         }

         this.referee1 = "";
         this.referee2 = "";
         if (this.match.referee1 != '')
            this.referee1 = this.match.referee1 + ((this.isCoachOrAssistantOrSupervisorConnected && (this.match.transportCost1 + this.match.indemnity1) != 0) ? " (" + (this.match.transportCost1 + this.match.indemnity1).toFixed(2) + " €)" : "");
         if (this.match.referee2 != '')
            this.referee2 = this.match.referee2 + ((this.isCoachOrAssistantOrSupervisorConnected && (this.match.transportCost2 + this.match.indemnity2) != 0) ? " (" + (this.match.transportCost2 + this.match.indemnity2).toFixed(2) + " €)" : "");
      }

      if (this.isMatchResult) {
         this.score = this.match.score;
         this.scoreDetail = this.match.scoreDetail;
         this.scoreReserve = this.match.scoreReserve;

         if (this.score != "" && this.score != null) {
            let scores = this.score.split('-');
            this.isVictory = null;
            if (  (Number(scores[0]) == 3 && Number(scores[1]) == 0) ||
                  (Number(scores[0]) == 3 && Number(scores[1]) == 1) ||
                  (Number(scores[0]) == 3 && Number(scores[1]) == 2) ||
                  (Number(scores[0]) == 0 && Number(scores[1]) == 3) ||
                  (Number(scores[0]) == 1 && Number(scores[1]) == 3) ||
                  (Number(scores[0]) == 2 && Number(scores[1]) == 3)
            )
            {
               this.isVictory = (this.isAtHome && Number(scores[0]) > Number(scores[1]) || (!this.isAtHome && Number(scores[0]) < Number(scores[1])));
            }
         }
      }
   }

  public ngOnChanges(changes: SimpleChanges) {
      if (changes.eventClub?.currentValue != changes.eventClub?.previousValue) {
         this.initialize();
      }
   }

  public ngOnInit() {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; });
      this.subjectService.IsTrainingPlayerCacheLoadedSubject.subscribe(value => {
         if (value) {
            this.initialize();
         }
      });
   }

  public onEventClick() {
      if (this.isClickable)
         this.onClick.emit();
   }

   public onEventCopyToClipboard(address: string) {
      this.clipboard.copy(address);
      this.frameworkService.displayAlert(true, "L'adresse a été copiée dans le presse-papier");
   }
   
}
