<div>
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-content>

            <!-- RESULTATS DU WEEKEND -->
            <div class="matcard matcardFloat" *ngIf="allMatchResultsOfWeekend.size > 0">
               <app-panel [widthPanel]="'800px'">
                  <span panel-title><span class="material-icons matcard-title-icon" style="margin-left:auto">campaign
                     </span> Résultats du weekend <span *ngIf="isInformationEditable() && !isTurnAdminButtonsOff"
                        class="material-icons editButton animatedButton"
                        [routerLink]="['/adminactivitylist']">more_horiz</span></span>
                  <div panel-content>
                     <ul class="timelineActivity">
                        <ng-container *ngFor="let eventClub of allMatchResultsOfWeekendArray; let i = index">
                           <li
                           [class]="getTiPaddingClass(i, allMatchResultsOfWeekendArray, false)">
                           <ti [timelineType]="EnumTimelineType.RESULT" [eventClub]="eventClub[1]"
                              [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                              [isLabelHidden]="getTiPaddingClassCondition(i, allMatchResultsOfWeekendArray)"
                              [class]="getBackgroundOfMatch(eventClub[1].event)"
                              [classContent]="getBorderOfMatch(eventClub[1].event)" timeline-panel
                              [date]="getCalendarActivityDate(eventClub[1].day)" [isUserConnected]="true">
                           </ti>
                        </li>
                        </ng-container>
                     </ul>
                  </div>
               </app-panel>
            </div>

            <!-- EVENEMENTS DE LA SEMAINE -->
            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'800px'">
                  <span panel-title><span class="material-icons matcard-title-icon" style="margin-left:auto">campaign
                     </span>{{getEventTitle(isMatchPlay(), allMatchsOfWeekend.size, allMatchResultsOfWeekend.size)}} <span *ngIf="isInformationEditable() && !isTurnAdminButtonsOff"
                        class="material-icons editButton animatedButton"
                        [routerLink]="['/adminactivitylist']">more_horiz</span></span>
                  <div panel-content>
                     <ul class="timelineActivity">
                        <!-- EVENEMENTS DE LA SEMAINE -->
                        <ng-container *ngIf="allMatchsOfWeekend.size > 0">
                           <ng-container *ngFor="let eventClub of allMatchsOfWeekendArray; let i = index">
                              <!-- ANNIVERSAIRES -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.BIRTHDAY">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchsOfWeekendArray, true)">
                                    <ti [timelineType]="EnumTimelineType.BIRTHDAY" 
                                       [class]="'backgroundBirthday'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchsOfWeekendArray)"
                                       [date]="getCalendarActivityDateByMonthAndYear(eventClub[1].day)" [isUserConnected]="true"
                                       [text]="getInfoBirthday(eventClub[1].event, false)"></ti>
                                 </li>
                              </ng-container>

                              <!-- RENCONTRES (A JOUER) -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.CHAMPIONSHIP || 
                                         eventClub[1].clubActivityType == EnumClubActivityType.HAINAUT_CUP || 
                                         eventClub[1].clubActivityType == EnumClubActivityType.FRIENDLY_MATCH_DAY || 
                                         eventClub[1].clubActivityType == EnumClubActivityType.TOURNAMENT_DAY || 
                                         eventClub[1].clubActivityType == EnumClubActivityType.YOUNG ">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchsOfWeekendArray, false)">
                                    <ti [timelineType]="EnumTimelineType.FULL" 
                                        [eventClub]="eventClub[1]"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchsOfWeekendArray)"
                                       [class]="getBackgroundOfMatch(eventClub[1].event)"
                                       [classContent]="getBorderOfMatch(eventClub[1].event)" timeline-panel
                                       [date]="getCalendarActivityDate(eventClub[1].day)" [isUserConnected]="true">
                                    </ti>
                                 </li>
                              </ng-container>

                              <!-- REUNIONS DE COMITE -->
                              <ng-container
                                 *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.COMITEE_MEETING">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchsOfWeekendArray, true)">
                                    <ti [timelineType]="EnumTimelineType.COMITEE" 
                                       [class]="'backgroundMeeting'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchsOfWeekendArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getComiteeMeetingInfo(eventClub[1].event)"></ti>
                                 </li>
                              </ng-container>

                              <!-- FERMETURE DE SALLE -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.CLOSING">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchsOfWeekendArray, false)">
                                    <ti [timelineType]="EnumTimelineType.COMITEE" 
                                       [class]="'backgroundClosingDay'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchsOfWeekendArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getClosingDayInfo(eventClub[1].reason)"></ti>
                                 </li>
                              </ng-container>

                              <!-- SOUPER -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.SUPPER">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchsOfWeekendArray, false)">
                                    <ti [timelineType]="EnumTimelineType.SUPPER" 
                                       [class]="'backgroundEvent'"
                                       [eventClub]="eventClub[1]"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchsOfWeekendArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getEventInfo(eventClub[1].event)"
                                       [isNetworkStatusOnline]="isNetworkStatusOnline"
                                       [memberPresenceCountActivity]="memberPresenceCountActivity"></ti>
                                 </li>
                              </ng-container>

                              <!-- AG MEETING -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.AG_MEETING">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchsOfWeekendArray, true)">
                                    <ti [timelineType]="EnumTimelineType.ACTIVITY" 
                                       [class]="'backgroundMeeting'"
                                       [eventClub]="eventClub[1]"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchsOfWeekendArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getEventInfo(eventClub[1].event)"
                                       [isNetworkStatusOnline]="isNetworkStatusOnline"
                                       [memberPresenceCountActivity]="memberPresenceCountActivity"></ti>
                                 </li>
                              </ng-container>

                              <!-- TOURNAMENT INFO -->
                              <ng-container
                                 *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.TOURNAMENT_INFO">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchsOfWeekendArray, true)">
                                    <ti [timelineType]="EnumTimelineType.ACTIVITY" 
                                       [class]="'backgroundTournament'"
                                       [eventClub]="eventClub[1]"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchsOfWeekendArray)"  
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getEventInfo(eventClub[1].event)"
                                       [isNetworkStatusOnline]="isNetworkStatusOnline"
                                       [memberPresenceCountActivity]="memberPresenceCountActivity"></ti>
                                 </li>
                              </ng-container>
                           </ng-container>
                        </ng-container>
                     </ul>
                  </div>
               </app-panel>
            </div>

            <!-- PROCHAINS EVENEMENTS -->
            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'800px'">
                  <span panel-title>
                     <div class="flexStart">
                        <div>
                           <span class="material-icons matcard-title-icon" style="margin-left:auto">campaign</span>
                           {{getNextEventTitle(allMatchNext.size)}}
                        </div>
                        <div class="alignmentRight">
                           <div style="padding-right:20px" *ngIf="accessRightsService.isUserConnected">
                              <app-button [icon]="isBirthdayDisplayed ? 'cake visibility' : 'cake visibility_off'"
                                 class="spanButtonSeparator" (onClick)="turnOnOffBirthdayVisibility()"></app-button>
                           </div>
                        </div>
                     </div>
                  </span>
                  <div panel-content>
                     <ul class="timelineActivity">
                        <!-- EVENEMENTS A VENIR -->
                        <ng-container *ngIf="allMatchNext.size > 0">
                           <ng-container *ngFor="let eventClub of allMatchNextArray; let i = index">

                              <!-- ANNIVERSAIRES -->
                              <ng-container>
                                 <li [hidden]="!isBirthdayDisplayed"
                                    [class]="getTiPaddingClass(i, allMatchNextArray, true)"
                                    *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.BIRTHDAY">
                                    <ti [timelineType]="EnumTimelineType.BIRTHDAY" 
                                       [class]="'backgroundBirthday'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchNextArray)"
                                       [date]="getCalendarActivityDateByMonthAndYear(eventClub[1].day)"
                                       [isUserConnected]="true" [text]="getInfoBirthday(eventClub[1].event, false)">
                                    </ti>
                                 </li>
                              </ng-container>

                              <!-- RENCONTRES (A JOUER) -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.CHAMPIONSHIP || 
                                         eventClub[1].clubActivityType == EnumClubActivityType.HAINAUT_CUP || 
                                         eventClub[1].clubActivityType == EnumClubActivityType.EXTRA_TRAINING_DAY ||
                                         eventClub[1].clubActivityType == EnumClubActivityType.FRIENDLY_MATCH_DAY || 
                                         eventClub[1].clubActivityType == EnumClubActivityType.TOURNAMENT_DAY || 
                                         eventClub[1].clubActivityType == EnumClubActivityType.YOUNG">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchNextArray, false)">
                                    <ti [timelineType]="EnumTimelineType.FULL" 
                                       [eventClub]="eventClub[1]"
                                       [class]="getBackgroundOfMatch(eventClub[1].event)"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchNextArray)"
                                       [classContent]="getBorderOfMatch(eventClub[1].event)" timeline-panel
                                       [date]="getCalendarActivityDate(eventClub[1].day)" [isUserConnected]="true">
                                    </ti>
                                 </li>
                              </ng-container>

                              <!-- REUNIONS DE COMITE -->
                              <ng-container
                                 *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.COMITEE_MEETING">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchNextArray, false)">
                                    <ti [timelineType]="EnumTimelineType.COMITEE" 
                                       [class]="'backgroundMeeting'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchNextArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getComiteeMeetingInfo(eventClub[1].event)"></ti>
                                 </li>
                              </ng-container>

                              <!-- FERMETURE DE SALLE -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.CLOSING">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchNextArray, true)">
                                    <ti [timelineType]="EnumTimelineType.COMITEE" 
                                       [class]="'backgroundClosingDay'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchNextArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getClosingDayInfo(eventClub[1].reason)"></ti>
                                 </li>
                              </ng-container>

                              <!-- SOUPER -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.SUPPER">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchNextArray, false)">
                                    <ti [timelineType]="EnumTimelineType.SUPPER" 
                                       [eventClub]="eventClub[1]"
                                       [class]="'backgroundEvent'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchNextArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getEventInfo(eventClub[1].event)"
                                       [isNetworkStatusOnline]="isNetworkStatusOnline"
                                       [memberPresenceCountActivity]="memberPresenceCountActivity"></ti>
                                 </li>
                              </ng-container>

                              <!-- AG MEETING -->
                              <ng-container *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.AG_MEETING">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchNextArray, true)">
                                    <ti [timelineType]="EnumTimelineType.ACTIVITY"
                                       [eventClub]="eventClub[1]"
                                       [class]="'backgroundMeeting'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchNextArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getEventInfo(eventClub[1].event)"
                                       [isNetworkStatusOnline]="isNetworkStatusOnline"
                                       [memberPresenceCountActivity]="memberPresenceCountActivity"></ti>
                                 </li>
                              </ng-container>

                              <!-- TOURNAMENT INFO -->
                              <ng-container
                                 *ngIf="eventClub[1].clubActivityType == EnumClubActivityType.TOURNAMENT_INFO">
                                 <li
                                    [class]="getTiPaddingClass(i, allMatchNextArray, true)">
                                    <ti [timelineType]="EnumTimelineType.ACTIVITY"
                                       [eventClub]="eventClub[1]"
                                       [class]="'backgroundTournament'"
                                       [icon]="getIconTimeLine(eventClub[1].clubActivityType)"
                                       [isLabelHidden]="getTiPaddingClassCondition(i, allMatchNextArray)"
                                       [date]="getCalendarActivityDate(eventClub[1].day)"
                                       [isUserConnected]="accessRightsService.isUserConnected"
                                       [text]="getEventInfo(eventClub[1].event)"
                                       [isNetworkStatusOnline]="isNetworkStatusOnline"
                                       [memberPresenceCountActivity]="memberPresenceCountActivity"></ti>
                                 </li>
                              </ng-container>
                           </ng-container>
                        </ng-container>
                     </ul>
                  </div>
               </app-panel>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
</div>