<mat-card>
   <mat-card-header>
      <mat-card-title>
         {{importDataTitleDisplay}} {{importChampionshipDisplay}}
      </mat-card-title>
   </mat-card-header>


   <mat-card>
      <mat-card-header>
         <mat-card-title>
            A partir de Clubee
         </mat-card-title>
      </mat-card-header>
      <mat-card-content>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Se loguer sur clubee</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'" [text]="'Dashboard Clubee'"
                     [type]="EnumHRefType.HttpNewWindow" [href]="'https://mobile.clubee.com/app?lang=fr_fr&c=profile&profile=dashboard'" alt="">
                  </app-buttonhref>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel [widthPanel]="'600px'">
               <span panel-title>Publication de la liste des membres affiliés (Clubee)</span>
               <div panel-content>
                  <input style="display:none" id="input-file-id1" multiple type="file"
                     (change)="onFilesSelected1($event)" />
                  <span class="buttons"><label class="nav-link navbarLink buttonize" for="input-file-id1">
                        Choisir ...
                     </label>
                     &nbsp;{{selectedFiles1.length}} fichier(s) sélectionné(s)&nbsp;
                     <button mat-button class="nav-link navbarLink buttonize" [disabled]="selectedFiles1.length === 0" (click)="uploadFiles1()">Upload</button>
                  </span>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel [widthPanel]="'600px'">
               <span panel-title>Publication de membres non affiliés (hors clubee)</span>
               <div panel-content>
                  <input style="display:none" id="input-file-id2" multiple type="file"
                     (change)="onFilesSelected2($event)" />
                  <span class="buttons"><label class="nav-link navbarLink buttonize" for="input-file-id2">
                        Choisir ...
                     </label>
                     &nbsp;{{selectedFiles2.length}} fichier(s) sélectionné(s)&nbsp;
                     <button mat-button class="nav-link navbarLink buttonize" [disabled]="selectedFiles2.length === 0" (click)="uploadFiles2()">Upload</button>
                  </span>
               </div>
            </app-panel>
         </div>
         <div class="matcard matcardFloat">
            <app-panel [widthPanel]="'600px'">
               <span panel-title>Publication des matchs U17</span>
               <div panel-content>
                  <input style="display:none" id="input-file-id3" multiple type="file"
                     (change)="onFilesSelected3($event)" />
                  <span class="buttons"><label class="nav-link navbarLink buttonize" for="input-file-id3">
                        Choisir ...
                     </label>
                     &nbsp;{{selectedFiles3.length}} fichier(s) sélectionné(s)&nbsp;
                     <button mat-button class="nav-link navbarLink buttonize" [disabled]="selectedFiles3.length === 0" (click)="uploadFiles3()">Upload</button>
                  </span>
               </div>
            </app-panel>
         </div>
      </mat-card-content>
   </mat-card>
   <mat-card>
      <mat-card-header>
         <mat-card-title>
            A partir d'Achvb
         </mat-card-title>
      </mat-card-header>
      <mat-card-content>
         <div class="matcard matcardFloat">
            <app-panel>
               <span panel-title>Les jeunes</span>
               <div panel-content>
                  <app-buttonhref [widthButton]="'600px'" [isBackgroundOpaque]="true" [icon]="'link'"
                     [text]="exportFilesFromAchvbPortal" [type]="EnumHRefType.HttpNewWindow"
                     [href]="'https://www.achvb.be/'" alt="">
                  </app-buttonhref>
                  <!-- <app-button icon="input" [class]="'spanButtonSeparator'" [text]="'ImportYoung' | translate"
                     [isBackgroundOpaque]="true" (onClick)="fileInputYoung.click()"></app-button>
                  <input hidden (change)="onFileChange($event, true)" #fileInputYoung type="file" multiple="false"> -->
               </div>
            </app-panel>
         </div>

      </mat-card-content>
   </mat-card>



   <div class="alertShow">
      <app-alertShow></app-alertShow>
   </div>
</mat-card>