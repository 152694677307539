import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { team } from 'src/sites/vabourlettis/models/team';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { FormService } from 'src/sites/vabourlettis/services/form.service';
import { TeamService } from 'src/sites/vabourlettis/services/team.service';
import { enumCacheStatusLoad, enumDatagridHeaderOrder } from "../../enum/Enum";
import { datagridFilterDetail } from '../../models/system/datagridFilter';
import { datagridParam } from '../../models/system/datagridParam';
import { user } from "../../models/user/user";
import { AlertShowService } from '../../services/alertShow.service';
import { DatagridService } from '../../services/datagrid.service';
import { FrameworkService } from '../../services/framework.service';
import { UserService } from "../../services/user.service";
import { SubjectService } from '../../services/subject.service';

type AOA = any[][];

@Component({
   selector: 'app-adminUser-list',
   templateUrl: './adminUser-list.component.html',
   styleUrls: ['./adminUser-list.component.scss']
})
export class AdminUserListComponent implements OnInit {
   public allTeamOfCurrentChampionship: team[];
   public allUsersCurrentCS;
   //public allUsersPreviousCS;
   public championshipNameDisplay: string = "";
   public currentChampionshipSysId = 0;
   public data_affiliation: AOA;
   public data_dirigeants: AOA;
   public data_encadrants: AOA;
   public data_joueurs: AOA;
   public data_licences: AOA;
   public data_salles: AOA;
   public datagridParam: datagridParam<user>;
   public dialog: any;
   public exportFilesFromFvwbPortal: string = "";
   public importChampionshipDisplay: string = "";
   public importDataDescriptionDisplay: string = "";
   public importDataTitleDisplay: string = "";
   public isLoading: boolean = false;
   public previousChampionshipSysId = 0;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private datagridService: DatagridService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(r => {
         if (this.isLoading) this.refresh();
      });
   }

   public getDatagridFilters($event) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public ngOnInit() {
   }

   public onFileChange(evt: any) {
      // const target: DataTransfer = <DataTransfer>(evt.target);
      // if (target.files.length !== 1) throw new Error('Cannot use multiple files');

      // const reader: FileReader = new FileReader();
      // reader.onload = (e: any) => {
      //    const wb: XLSX.WorkBook = XLSX.read(e.target.result, { type: 'binary', cellDates: true, dateNF: 'jj-mm-aaaa;@', cellNF: false, cellText: false });

      //    //0 : affiliations
      //    const ws_affiliation: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
      //    /*
      //    Excel columns order "Liste+des+affiliations.xlsx"
      //    00 N° Membre (Numéro de licence)
      //    01 Nom
      //    02 Prénom	
      //    03 Date de naissance	
      //    04 Nationalité	
      //    05 N° National	
      //    06 Genre	
      //    07 Club	
      //    08 Date de début	
      //    09 Date de fin	
      //    10 Statut affiliation	
      //    11 Date statut	
      //    12 Actif à ce jour	
      //    13 Type de licence	
      //    14 Adresse	
      //    15 Code postal	
      //    16 Ville	
      //    17 Adresse email 1	
      //    18 Adresse email 2	
      //    19 Adresse email 3	
      //    20 Numéro de tél. 1	
      //    21 Numéro de tél. 2	
      //    22 Numéro de tél. 3	
      //    23 En dette	
      //    24 Suspendu
      //    25 Suspendu du	
      //    26 au
      //    */
      //    const ws_dirigeants: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
      //    //1 : dirigeants+de+club
      //    /*
      //    00 N° Membre
      //    01 Nom
      //    02 Prénom	
      //    03 Type de licence	
      //    04 Fonction	(Secrétaire, Trésorier, Président)
      //    05 N° Club	
      //    06 Club	
      //    07 Province	
      //    08 Date de début	
      //    09 Date de fin	
      //    10 Numéro de tél.	
      //    11 Email	
      //    12 Adresse	
      //    13 Code postal	
      //    14 Ville	
      //    15 Destinataire document	(Oui/Non)
      //    16 Destinataire courrier (Oui/Non)
      //    */
      //    const ws_encadrants: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[2]];
      //    //2 : encadrants
      //    /*
      //    00 N° Membre	
      //    01 Nom	
      //    02 Prénom	
      //    03 Type de licence	
      //    04 Fonction	(Marqueur - Délégué, Coach)
      //    05 Club	
      //    06 Province	
      //    07 Matricule	
      //    08 Date de début	
      //    09 Date de fin	
      //    10 Numéro de tél.	
      //    11 Adresse email	
      //    12 Adresse	
      //    13 Code postal	
      //    14 Ville	
      //    15 Destinataire document	
      //    16 Destinataire courrier	
      //    17 IsTypeAllowed
      //    */
      //    const ws_joueurs: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[3]];
      //    //3 : joueurs+par+équipe
      //    /*
      //    00 N° Membre	
      //    01 Nom	
      //    02 Prénom	
      //    03 Date de naissance	
      //    04 Type de licence	
      //    05 Equipe	
      //    06 Niveau	
      //    07 Division	
      //    08 Série	
      //    09 Club	
      //    10 N° Club	
      //    11 N° Maillot	
      //    12 Position	
      //    13 Liste de force	
      //    14 Liste initiale
      //    */
      //    const ws_licences: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[4]];
      //    //4 : licences
      //    /*
      //    00 N° Membre	
      //    01 Nom	
      //    02 Prénom	
      //    03 Date de naissance	
      //    04 Genre	
      //    05 N° Club	
      //    06 Club	
      //    07 Discipline	
      //    08 Catégorie	
      //    09 Sous-catégorie	
      //    10 Date de début	
      //    11 Date de fin	
      //    12 Type de licence	
      //    13 Statut	
      //    14 Adresse	
      //    15 Code postal	
      //    16 Ville	
      //    17 Adresse email 1	
      //    18 Adresse email 2	
      //    19 Adresse email 3	
      //    20 Numéro de tél. 1	
      //    21 Numéro de tél. 2	
      //    22 Numéro de tél. 3	
      //    23 Actif à ce jour
      //    */
      //    const ws_salles: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[5]];
      //    //5 : salles+et+terrains
      //    /*
      //    00 Nom	
      //    01 Adresse	
      //    02 Code postal	
      //    03 Ville	
      //    04 Homologation	
      //    05 Terrain	
      //    06 Clé	
      //    07 Code	
      //    08 DocumentId
      //    */

      //    this.data_affiliation = <AOA>(XLSX.utils.sheet_to_json(ws_affiliation, { header: 1 }));
      //    this.data_dirigeants = <AOA>(XLSX.utils.sheet_to_json(ws_dirigeants, { header: 1 }));
      //    this.data_encadrants = <AOA>(XLSX.utils.sheet_to_json(ws_encadrants, { header: 1 }));
      //    this.data_joueurs = <AOA>(XLSX.utils.sheet_to_json(ws_joueurs, { header: 1 }));
      //    this.data_licences = <AOA>(XLSX.utils.sheet_to_json(ws_licences, { header: 1 }));
      //    this.data_salles = <AOA>(XLSX.utils.sheet_to_json(ws_salles, { header: 1 }));

      //    let data_joueursCount = 0;
      //    this.data_joueurs.forEach(member => {
      //       if ((member[0] + "").trim() != "N° Membre") {
      //          data_joueursCount++;
      //       }
      //    });

      //    if (data_joueursCount == 0) {
      //       this.alertShowService.clear();
      //       this.alertShowService.error(this.translateService.instant('MessageImportUnfinishedError1'));
      //       timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
      //    }
      //    else {
      //       //Add teams if doesn't exists Niveau 06, Division 07, Série 08
      //       /*
      //       If Série empty -> take Division. DB : division (Provincial) + teamCategory (P3BM)

      //       Niveau	    Division	Série
      //       Provincial	P3D	      P3BD
      //       PMin	      P3M	      P3BM
      //       Jeunes	    cad-g-HT
      //       */

      //       const teamsToInsert = [];

      //       //Add no team for member who are not in a team
      //       let teamFound = this.allTeamOfCurrentChampionship.FirstOrDefault(i => i.teamCategory == "");
      //       if (teamFound == null) {
      //          let newTeam = new team();
      //          newTeam.division = "";
      //          newTeam.teamCategory = "";
      //          newTeam.championshipSysId = this.currentChampionshipSysId;
      //          teamsToInsert.push(newTeam);
      //       }

      //       this.data_joueurs.forEach(member => {
      //          if ((member[0] + "").trim() != "N° Membre") {
      //             let division = member[6] == "PMin" ? "Provincial" : this.frameworkService.uppercaseFirstLetterAndLowerOther(member[6]);
      //             let teamCategory = (member[8] == undefined || (member[8] + "").trim() == "") ? member[7]?.toUpperCase() : member[8]?.toUpperCase();

      //             let teamAlreadyAddedFound = teamsToInsert.FirstOrDefault(i => i.teamCategory == teamCategory);
      //             if (teamAlreadyAddedFound == null) {
      //                let teamFound = this.allTeamOfCurrentChampionship.FirstOrDefault(i => i.teamCategory == teamCategory);

      //                if (teamFound == null) {
      //                   let newTeam = new team();
      //                   newTeam.division = division;
      //                   newTeam.teamCategory = teamCategory;
      //                   newTeam.championshipSysId = this.currentChampionshipSysId;
      //                   teamsToInsert.push(newTeam);
      //                }
      //             }
      //          }
      //       }
      //       );

      //       this.teamService.insertAll(teamsToInsert).subscribe({
      //          next: data => {
      //             this.formService.getAllActiveWithIncluding("team").subscribe(
      //                (teamData: any[]) => {
      //                   let allNewTeamOfCurrentChampionship = teamData.filter(i => i.championship?.isCurrent);
      //                   let noTeam = allNewTeamOfCurrentChampionship.FirstOrDefault(i => i.teamCategory == "");

      //                   //Insert users (from affiliations)
      //                   const usersToInsert = [];
      //                   const usersToUpdate = [];

      //                   this.data_affiliation.forEach(member => {
      //                      if (member[0].trim() != "N° Membre") {
      //                         let newUser = new user();

      //                         newUser.licenceNumber = member[0].trim();
      //                         newUser.lastName = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[1]);
      //                         newUser.firstName = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[2]);
      //                         newUser.isUserLoginInactive = false;
      //                         newUser.username = this.frameworkService.buildUsername(newUser.firstName, newUser.lastName);
      //                         newUser.birthdayDate = this.frameworkService.getDateOnlyFormatDatabaseFromJJMMAAAAA(member[3]);
      //                         newUser.nationality = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]);
      //                         newUser.nationalNumber = member[5];
      //                         newUser.gender = member[6] == "Masculin" ? true : false; //Féminin/Masculin
      //                         newUser.startAffiliate = this.frameworkService.getDateOnlyFormatDatabaseFromJJMMAAAAA(member[8]);
      //                         newUser.address = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[14]);
      //                         newUser.zipCode = member[15];
      //                         newUser.locality = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[16]);
      //                         newUser.email = member[17].toLowerCase();
      //                         newUser.phone1 = this.frameworkService.unFormatPhone(member[20]);
      //                         newUser.phone2 = this.frameworkService.unFormatPhone(member[21]);
      //                         newUser.workgroupSysId = enumWorkgroup.UserReadOnly;
      //                         newUser.language = "fr";
      //                         newUser.teamSysId = noTeam?.sysId;
      //                         newUser.team2SysId = null;
      //                         newUser.password = "d77d441a64dd4a5f82c0ed2dedc47d0e"; //Mot de passe par défaut

      //                         //Check if the user can be added into current championship
      //                         let userFoundInCurrent: user[] = this.allUsersCurrentCS.filter(i => i.licenceNumber == newUser.licenceNumber);
      //                         let userFoundInPrevious: user[] = this.allUsersPreviousCS.filter(i => i.licenceNumber == newUser.licenceNumber);
      //                         let updateUser: user;

      //                         if (userFoundInCurrent != null && userFoundInCurrent.length > 0) {
      //                            // User has been found in current championship (based on licenceNumber)
      //                            // -> Update it
      //                            updateUser = userFoundInCurrent[0];
      //                            updateUser.lastName = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[1]);
      //                            updateUser.firstName = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[2]);
      //                            updateUser.username = this.frameworkService.buildUsername(newUser.firstName, newUser.lastName);
      //                            updateUser.birthdayDate = this.frameworkService.getDateOnlyFormatDatabaseFromJJMMAAAAA(member[3]);
      //                            updateUser.nationality = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]);
      //                            updateUser.nationalNumber = member[5];
      //                            updateUser.gender = member[6] == "Masculin" ? true : false; //Féminin/Masculin
      //                            updateUser.startAffiliate = this.frameworkService.getDateOnlyFormatDatabaseFromJJMMAAAAA(member[8]);
      //                            updateUser.address = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[14]);
      //                            updateUser.zipCode = member[15];
      //                            updateUser.locality = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[16]);
      //                            updateUser.email = member[17].toLowerCase();
      //                            updateUser.phone1 = this.frameworkService.unFormatPhone(member[20]);
      //                            updateUser.phone2 = this.frameworkService.unFormatPhone(member[21]);
      //                            //updateUser.teamSysId = noTeam?.sysId;
      //                            //updateUser.team2SysId = null; // Ne pas écraser la valeur car si pas d'équipe enregistrée (jeunes), le membre n'est plus lié à l'équipe liée manuellement
      //                            updateUser.roleInTeam = "";
      //                            updateUser.roleInComity = "";
      //                            updateUser.roleInClub = "";

      //                            updateUser = JSON.parse(JSON.stringify(updateUser, (key, value) => {
      //                               if (key === "team" || key === "workgroup") {
      //                                  return undefined;
      //                               }
      //                               return value;
      //                            }));
      //                            usersToUpdate.push(updateUser);
      //                         }
      //                         else {
      //                            // User has NOT been found in current championship (based on licenceNumber)
      //                            //-> Add it and update it with data of previous championship (not all data !)
      //                            if (userFoundInPrevious != null && userFoundInPrevious.length > 0) {
      //                               newUser.isUserLoginInactive = userFoundInPrevious[0].isUserLoginInactive;
      //                               newUser.activationTime = userFoundInPrevious[0].activationTime;
      //                               newUser.avatar = userFoundInPrevious[0].avatar;
      //                               newUser.isNotificationByEmailActivate = userFoundInPrevious[0].isNotificationByEmailActivate;
      //                               newUser.jacketSize = userFoundInPrevious[0].jacketSize;
      //                               newUser.language = userFoundInPrevious[0].language;
      //                               newUser.nickName = userFoundInPrevious[0].nickName;
      //                               newUser.password = userFoundInPrevious[0].password;
      //                               newUser.picturePersonal = userFoundInPrevious[0].picturePersonal;
      //                               newUser.roleInTeam = "";
      //                               newUser.roleInComity = "";
      //                               newUser.roleInClub = "";
      //                               newUser.roleInClubCustom = userFoundInPrevious[0].roleInClubCustom;
      //                               newUser.shortsSize = userFoundInPrevious[0].shortsSize;
      //                               newUser.token = userFoundInPrevious[0].token;
      //                               newUser.username = userFoundInPrevious[0].username;
      //                               newUser.workgroupSysId = userFoundInPrevious[0].workgroupSysId;
      //                            }
      //                            usersToInsert.push(newUser);
      //                         }
      //                      }
      //                   });

      //                   // Update users (from dirigeants) -> Add fonction 04 (Secrétaire, Trésorier, Président)
      //                   this.data_dirigeants.forEach(member => {
      //                      if ((member[0] + "").trim() != "N° Membre") {
      //                         let userToInsert = usersToInsert.FirstOrDefault(i => i.licenceNumber == (member[0] + "").trim());
      //                         if (userToInsert != null) {
      //                            //member[9] = date de fin (démission)
      //                            userToInsert.roleInComity = (member[9] == undefined) ? this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]) : "";
      //                         }
      //                         let userToUpdate = usersToUpdate.FirstOrDefault(i => i.licenceNumber == (member[0] + "").trim());
      //                         if (userToUpdate != null) {
      //                            //member[9] = date de fin (démission)
      //                            userToUpdate.roleInComity = (member[9] == undefined) ? this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]) : "";
      //                         }
      //                      }
      //                   }
      //                   );

      //                   // joueurs+par+équipe 12 Position	(Passeur, Central, Universel, Opposit, Attaquant)
      //                   // encadrants         04 Fonction	(Marqueur - Délégué, Coach) -> RoleInClub
      //                   //                                                                 RoleInClubCustom
      //                   // dirigeants+de+club 04 Fonction	(Secrétaire, Trésorier, Président) -> RoleInComity

      //                   // Update users (from encadrants) -> Add fonction 04
      //                   this.data_encadrants.forEach(member => {
      //                      if ((member[0] + "").trim() != "N° Membre") {
      //                         let userToInsert = usersToInsert.FirstOrDefault(i => i.licenceNumber == (member[0] + "").trim());
      //                         if (userToInsert != null) {
      //                            if (userToInsert.roleInClub == "" || userToInsert.roleInClub == undefined)
      //                               userToInsert.roleInClub = (member[4] == null ? "" : this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]));
      //                            else
      //                               userToInsert.roleInClub += (member[4] == null ? "" : ", " + this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]));
      //                            if (member[4] != null && member[4].toUpperCase() == enumRoles[enumRoles.Coach].toUpperCase())
      //                               userToInsert.roleInTeam = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]);
      //                         }

      //                         let userToUpdate = usersToUpdate.FirstOrDefault(i => i.licenceNumber == (member[0] + "").trim());
      //                         if (userToUpdate != null) {
      //                            if (userToUpdate.roleInClub == "" || userToUpdate.roleInClub == undefined)
      //                               userToUpdate.roleInClub = (member[4] == null ? "" : this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]));
      //                            else
      //                               userToUpdate.roleInClub += (member[4] == null ? "" : ", " + this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]));
      //                            if (member[4] != null && member[4].toUpperCase() == enumRoles[enumRoles.Coach].toUpperCase())
      //                               userToUpdate.roleInTeam = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[4]);
      //                         }
      //                      }
      //                   }
      //                   );

      //                   // Update users (from licences) -> Add Catégorie 08, Type de licence 12
      //                   this.data_licences.forEach(member => {
      //                      if ((member[0] + "").trim() != "N° Membre") {
      //                         let user = usersToInsert.FirstOrDefault(i => i.licenceNumber == (member[0] + "").trim());
      //                         if (user != null) {
      //                            user.category = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[8]);
      //                            user.licenceType = this.frameworkService.uppercaseFirstLetterAndLowerOther(member[12]);
      //                         }
      //                      }
      //                   }
      //                   );

      //                   // Update users (from joueurs) -> Add Niveau 06 , Division 07, Série 08, N° Maillot 11, Position 12
      //                   // If Série empty -> take Division

      //                   /*
      //                   Niveau	   Division	   Série
      //                   Provincial	P3D	      P3BD
      //                   PMin	      P3M	      P3BM
      //                   Jeunes	   cad-g-HT	
      //                   */
      //                   this.data_joueurs.forEach(member => {
      //                      if ((member[0] + "").trim() != "N° Membre") {
      //                         let teamCategory = (member[8] == undefined || (member[8] + "").trim() == "") ? member[7]?.toUpperCase() : member[8]?.toUpperCase();
      //                         let teamFound = allNewTeamOfCurrentChampionship.FirstOrDefault(i => i.teamCategory == teamCategory);

      //                         let userToInsert = usersToInsert.FirstOrDefault(i => i.licenceNumber == (member[0] + "").trim());
      //                         if (userToInsert != null) {
      //                            if (userToInsert.roleInClub?.includes(this.translateService.instant(enumRoles[enumRoles.Coach])) && member[11] == "")
      //                               userToInsert.jacketNumber = "-1";
      //                            else
      //                               userToInsert.jacketNumber = member[11];

      //                            if (userToInsert.roleInTeam == "" || userToInsert.roleInTeam == undefined)
      //                               userToInsert.roleInTeam = (member[12] == null ? "" : this.frameworkService.uppercaseFirstLetterAndLowerOther(member[12]));
      //                            else
      //                               userToInsert.roleInTeam += (member[12] == null ? "" : ", " + this.frameworkService.uppercaseFirstLetterAndLowerOther(member[12]));

      //                            //Add team
      //                            if (userToInsert.teamSysId == noTeam?.sysId)
      //                               userToInsert.teamSysId = teamFound?.sysId;
      //                            else
      //                               userToInsert.team2SysId = teamFound?.sysId;
      //                         }

      //                         let userToUpdate = usersToUpdate.FirstOrDefault(i => i.licenceNumber == (member[0] + "").trim());
      //                         if (userToUpdate != null) {
      //                            if (userToUpdate.roleInClub?.includes(this.translateService.instant(enumRoles[enumRoles.Coach])) && member[11] == "")
      //                               userToUpdate.jacketNumber = "-1";
      //                            else
      //                               userToUpdate.jacketNumber = member[11];

      //                            if (userToUpdate.roleInTeam == "" || userToUpdate.roleInTeam == undefined)
      //                               userToUpdate.roleInTeam = (member[12] == null ? "" : this.frameworkService.uppercaseFirstLetterAndLowerOther(member[12]));
      //                            else
      //                               userToUpdate.roleInTeam += (member[12] == null ? "" : ", " + this.frameworkService.uppercaseFirstLetterAndLowerOther(member[12]));

      //                            //Add team
      //                            // if (userToUpdate.teamSysId == noTeam?.sysId)
      //                            //    userToUpdate.teamSysId = teamFound?.sysId;
      //                            // else
      //                            //    userToUpdate.team2SysId = teamFound?.sysId;
      //                         }
      //                      }
      //                   }
      //                   );

      //                   if (usersToInsert.length > 0)
      //                      this.userService.insertAll(usersToInsert).subscribe({
      //                         next: data => {
      //                            this.alertShowService.success(this.translateService.instant('MessageImportFinishedSuccessfully'));
      //                            this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
      //                            timer(this.frameworkService.saveStatusResetTimeOnSuccess()).subscribe(x => { this.alertShowService.clear() });
      //                            this.subjectService.IsButtonInLoadingSubject.next(false);
      //                         },
      //                         error: err => {
      //                            this.alertShowService.clear();
      //                            this.alertShowService.error(this.translateService.instant('MessageImportUnfinishedDueToAnError'));
      //                            timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
      //                            this.subjectService.IsButtonInLoadingSubject.next(false);
      //                         }
      //                      });

      //                   if (usersToUpdate.length > 0)
      //                      this.userService.updateAll(usersToUpdate).subscribe({
      //                         next: data => {
      //                            this.alertShowService.success(this.translateService.instant('MessageImportFinishedSuccessfully'));
      //                            this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
      //                            timer(this.frameworkService.saveStatusResetTimeOnSuccess()).subscribe(x => { this.alertShowService.clear() });
      //                            this.subjectService.IsButtonInLoadingSubject.next(false);
      //                         },
      //                         error: err => {
      //                            this.alertShowService.clear();
      //                            this.alertShowService.error(this.translateService.instant('MessageImportUnfinishedDueToAnError'));
      //                            timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
      //                            this.subjectService.IsButtonInLoadingSubject.next(false);
      //                         }
      //                      });
      //                });
      //          },
      //          error: err => {
      //          }
      //       });
      //    }

      // };
      // reader.readAsBinaryString(target.files[0]);
   }

   public refresh() {
      this.isLoading = false;

      this.allTeamOfCurrentChampionship = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.championship.isCurrent);

      let currentChampionship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.isCurrent);

      this.currentChampionshipSysId = currentChampionship?.sysId;
      let previousChampionship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.yearStarting == (Number(currentChampionship.yearStarting) - 1).toString());
      if (previousChampionship != null)
         this.previousChampionshipSysId = previousChampionship?.sysId;
      else
         this.previousChampionshipSysId = 0;
      this.importChampionshipDisplay = this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", currentChampionship);

      // List of all users of the current championship
      this.allUsersCurrentCS = this.cacheService.getAllUsers();
      // List of all users of the previous championship
      //this.allUsersPreviousCS = this.cacheService.getAllUsersOfPC();

      //-> Set filter(s)
      let filterDetail = new datagridFilterDetail();
      filterDetail.dropdownSelectedValueOfLists = currentChampionship?.sysId;
      filterDetail.dropdownLists = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         }));
      filterDetail.labelText = this.championshipNameDisplay + " : ";
      filterDetail.name = "championship";
      filterDetail.idName = "team.championship.sysId";

      let datagridFilters = new Map<string, datagridFilterDetail>();
      datagridFilters.set(filterDetail.name, filterDetail);
      //<- Set filter(s)

      // Display the datagrid
      this.datagridParam = new datagridParam(
         {
            hidden: !this.isLoading,
            dataSource: this.cacheService.getAllUsers(),
            filters: null, //filters
            mainTitle: "AdminUsersListTitle",
            headerOrder: enumDatagridHeaderOrder.ApiByDefault,
            entity: new user(),
            urlApi: "C1028",
            paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
            formTitle: "UserTitle"
         });
      this.isLoading = true;

      //});
   }

   public translatePage() {
      //ExportFiles2FromFvwbPortal
      this.translateService.get(['ExportFiles2FromFvwbPortal'])
         .subscribe(translations => {
            this.exportFilesFromFvwbPortal = translations['ExportFiles2FromFvwbPortal'];
         });

      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });

      //ImportDataTitleDisplay
      this.translateService.get(['ImportDataTitleDisplay'])
         .subscribe(translations => {
            this.importDataTitleDisplay = translations['ImportDataTitleDisplay'];
         });

      //ImportData1DescriptionDisplay
      this.translateService.get(['ImportData1DescriptionDisplay'])
         .subscribe(translations => {
            this.importDataDescriptionDisplay = translations['ImportData1DescriptionDisplay'];
         });
   }

   // export(): void {
   //  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

   //   /* generate worksheet */
   //   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

   //   /* generate workbook and add the worksheet */
   //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   //   /* save to file */
   //   XLSX.writeFile(wb, this.fileName);
   // }
}
