<div>
   <div>
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">groups</span>{{titleDescriptionDisplay}}&nbsp;<span
                  *ngIf="isInformationEditable() && !isTurnAdminButtonsOff"
                  class="material-icons editButton animatedButton" [routerLink]="['/adminteamlist']">more_horiz</span>
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            <div class="matcard matcardFloat" *ngIf="isMainDisplay">
               <app-panel [widthPanel]="'500px'" *ngFor="let team of allTeamsFiltered">
                  <span panel-title>
                     <div class="flexStart">
                        <div>{{getNameOfTeam(team)}}</div>
                        <div class="alignmentRight"> <app-button [icon]="'portrait'" [isBackgroundOpaque]="true"
                              [text]="'Photos'" class="spanButtonSeparator"
                              (onClick)="onPersonalPictureClick(team)"></app-button></div>
                     </div>
                  </span>
                  <div panel-content>
                     <div>
                        <div class="flexCenter"><img *ngIf="team.picture != null && team.picture != ''"
                              [src]="getImgURL(team.picture)" loading="lazy" class="groupPicture" alt="Team">
                        </div>
                     </div>
                     <div [innerHtml]="getPlayersOfTeamForDisplay(team) | safe: 'html'">
                     </div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat" *ngIf="!isMainDisplay">
               <app-panel [widthPanel]="'500px'">
                  <span panel-title>
                     <div class="flexStart">
                        <div>{{getNameOfTeam(currentTeamDisplay)}}</div>

                        <div class="alignmentRight"><app-button [icon]="'arrow_back_ios'" [text]="''"
                              [isBackgroundOpaque]="true" class="spanButtonSeparator"
                              (onClick)="onBackClick()"></app-button>
                        </div>
                     </div>
                  </span>
                  <div panel-content>
                     <app-panel *ngFor="let player of getPlayersOfTeam(currentTeamDisplay)">
                        <span panel-title>

                           <div class="flexStart">
                              <div>{{player.firstName}} {{player.lastName}}</div>

                           </div>
                        </span>
                        <div panel-content>
                           <div>
                              <div class="flexCenter"><img *ngIf="player.pictureVab != null && player.pictureVab != ''"
                                    [src]="getImgURL(player.pictureVab)" loading="lazy" class="playerPicture"
                                    alt="Player">
                              </div>
                              <div class="flexCenter"><div *ngIf="player.pictureVab == null || player.pictureVab == ''">
                                 (indisponible)
                              </div>
                           </div>
                           </div>
                        </div>
                     </app-panel>
                  </div>
               </app-panel>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
</div>