import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, map, merge, Observable, of } from 'rxjs';
import { AccountService } from 'src/sites/core/services/account.service';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { PrintService } from 'src/sites/core/services/print.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { DataPrivacyDialogComponent, DataPrivacyDialogModel } from './common/footer/dataPrivacyDialog.component';
import { strings } from 'src/sites/vabourlettis/models/strings';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { enumCacheStatusLoad, enumCacheTypeLoad } from 'src/sites/core/enum/Enum';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { SignalRService } from 'src/sites/core/services/signalR.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { NotificationService } from 'src/sites/core/services/notification.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
   public isDisplayOnAMobile: boolean = false;
   public message: Observable<string | Object> | undefined;
   public showFooter: boolean = true;
   public showNavbar: boolean = true;
   public showSidebar: boolean = true;

   constructor(
      public dialog: MatDialog,
      public printService: PrintService,
      private swUpdate: SwUpdate,
      private subjectService: SubjectService,
      private cacheService: CacheService,
      private accountService: AccountService,
      private translateService: TranslateService,
      private appConfigService: AppConfigService,
      private frameworkService: FrameworkService,
      private signalRService: SignalRService,
      public storageService: StorageService,
      private notificationService: NotificationService,
      private router: Router) {
      this.getScreenSize();
    }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public getFormattedVersion(version: strings) {
      return version.value.replace("#SITE", "").replace("#", "").replace(",", " - ");
   }

   public async ngOnInit() {
      this.translateService.setDefaultLang('fr');
      this.translateService.use('fr');
      
      this.router.events.subscribe(event => {
         if (event instanceof NavigationEnd) {
            // Navigation ended successfully
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }

         if (event instanceof NavigationCancel) {
            // Navigation cancelled
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });

      if (this.appConfigService.getEnvironment() != "development")
      {
         //Try to restart hub each 5s if web api is again online (hub closed)
         setInterval(() => {
            //this.frameworkService.logInfo("Check HUB");
            if (this.signalRService.isHubConnectionClosed()) {
               this.signalRService.startConnection(true, this.subjectService);
            }
         }, 5000);
      }

      if (this.appConfigService.getEnvironment() == "production" || this.appConfigService.getEnvironment() == "test") {
         if (this.swUpdate.available) {
            this.swUpdate.available.subscribe(() => {
               alert("Une nouvelle mise à jour est disponible ... l'application va redémarrer.");
               window.location.replace(window.location.href.replace('http', !window.location.href.includes("localhost:4300") ? 'https' : 'http'));
               window.location.reload();
            });
         }
      }



      // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
      this.router.events.forEach((event) => {
         if (event instanceof NavigationStart) {
            if ((event['url'] == '/account/login') || (event['url'] == '/account/register') || (event['url'] == '/error-pages/404') || (event['url'] == '/error-pages/500')) {
               this.showSidebar = false;
               this.showNavbar = false;
               this.showFooter = false;
               document.querySelector('.main-panel')?.classList.add('w-100');
               document.querySelector('.page-body-wrapper')?.classList.add('full-page-wrapper');
               document.querySelector('.content-wrapper')?.classList.remove('auth', 'auth-img-bg',);
               document.querySelector('.content-wrapper')?.classList.remove('auth', 'lock-full-bg');
               if ((event['url'] == '/error-pages/404') || (event['url'] == '/error-pages/500')) {
                  document.querySelector('.content-wrapper')?.classList.add('p-0');
               }
            } else {
               this.showSidebar = true;
               this.showNavbar = true;
               this.showFooter = true;
            }
         }
      });

      // // Spinner for lazyload modules
      // this.router.events.forEach((event) => {
      //    if (event instanceof RouteConfigLoadStart) {
      //       this.isLoading = true;
      //    } else if (event instanceof RouteConfigLoadEnd) {
      //       this.isLoading = false;
      //    }
      // });

      // Scroll to top after route change
      this.router.events.subscribe((evt) => {
         if (!(evt instanceof NavigationEnd)) {
            return;
         }
         window.scrollTo(0, 0);
      });
   }

   public site() {
      return this.appConfigService.getSiteName();
   }

   public updatingImageExists(url: string, callback: (arg0: boolean) => void) {
      let img = new Image();
      img.onload = function () { callback(false); };
      img.onerror = function () { callback(true); };
      img.src = url;
   }
}
