import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, forkJoin } from 'rxjs';
import { activity } from 'src/sites/vabourlettis/models/activity';
import { activityByMember } from 'src/sites/vabourlettis/models/activityByMember';
import { eventClub } from 'src/sites/vabourlettis/models/eventClub';
import { queryFilter } from 'src/sites/vabourlettis/models/queryFilter';
import { ActivityByMemberService } from 'src/sites/vabourlettis/services/activityByMember.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { enumQueryFilterOp } from '../../enum/Enum';
import { AccessRightsService } from '../../services/accessRights.service';
import { FrameworkService } from '../../services/framework.service';
import { EncryptionService } from '../../services/encryption.service';
import { encryptedValue } from 'src/sites/vabourlettis/authentication/encryptedValue';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { SubjectService } from '../../services/subject.service';

@Component({
   selector: 'se',
   templateUrl: './supperEvent.component.html',
   styleUrls: ['./supperEvent.component.scss']
})
export class SupperEventComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public eventClub: eventClub;
  @Input() public isNetworkStatusOnline: boolean = true;
  @Input() public isPresenceLoaded: boolean = true;
  @Input() public isUserConnected: boolean = false;
  @Input() public memberPresenceCountActivity = new Map<string, string>();
  @Input() public visible: boolean = true;

  public form: FormGroup = new FormGroup({});
  public isPresentActivity = new Map<string, boolean>();
  public isStatDisplayed: boolean = false;
  public isUpdateActivity = new Map<string, boolean>();

  constructor(
      private frameworkService: FrameworkService,
      private subjectService: SubjectService,
      private activityByMemberService: ActivityByMemberService,
      private encryptionService: EncryptionService,
      public sanitizer: DomSanitizer,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService) {
   }

  public firstLoadPresenceValue() {
      this.isPresenceLoaded = false;
      let userLogged = this.storageService.getUserConnected();

      if (this.accessRightsService.isUserConnected) {
         let appServiceArray: Observable<any>[] = [];

         const queryFilters = [];
         queryFilters.push(new queryFilter({ propertyName: "UserSysId", operation: enumQueryFilterOp.Equals, value: userLogged.sysId }));

         appServiceArray.push(this.activityByMemberService.getAllWithoutIncluding(queryFilters));

         forkJoin(appServiceArray).subscribe(res => {
            var resDecrypted = this.encryptionService.decryptObject(false, res[0].value);
            if (resDecrypted.length > 0) {
               for (let cpt = 0; cpt < resDecrypted.length; cpt++) {
                  //Set the presence

                  this.isPresentActivity.set(resDecrypted[cpt].activitySysId.toString(), resDecrypted[cpt].isPresent >= 1);
                  //Set the updating status (save button disabled by default)
                  this.isUpdateActivity.set(resDecrypted[cpt].activitySysId.toString(), false);

                  if (this.form.controls["seniorCount" + resDecrypted[cpt].activitySysId] == undefined)
                     this.form.addControl("seniorCount" + resDecrypted[cpt].activitySysId.toString(), new FormControl(''));
                  this.form.get("seniorCount" + resDecrypted[cpt].activitySysId.toString()).setValue(resDecrypted[cpt].personSeniorCount);
                  if (this.form.controls["childCount" + resDecrypted[cpt].activitySysId] == undefined)
                     this.form.addControl("childCount" + resDecrypted[cpt].activitySysId.toString(), new FormControl(''));
                  this.form.get("childCount" + resDecrypted[cpt].activitySysId.toString()).setValue(resDecrypted[cpt].personChildCount);
                  if (this.form.controls["particularDemand" + resDecrypted[cpt].activitySysId] == undefined)
                     this.form.addControl("particularDemand" + resDecrypted[cpt].activitySysId.toString(), new FormControl(''));
                  this.form.get("particularDemand" + resDecrypted[cpt].activitySysId.toString()).setValue(resDecrypted[cpt].particularDemand);
                  if (this.form.controls["isPresent" + resDecrypted[cpt].activitySysId] == undefined)
                     this.form.addControl("isPresent" + resDecrypted[cpt].activitySysId.toString(), new FormControl(''));
                  this.form.get("isPresent" + resDecrypted[cpt].activitySysId.toString()).setValue(resDecrypted[cpt].isPresent >= 1);
               }
            }
            this.isPresenceLoaded = true;
         });
      }
   }

  public getAge(date) {
      const age = new Date(Date.now() - new Date(date).getTime());
      return Math.abs(age.getUTCFullYear() - 1970);
   }

  public getChildCount(sysId) {
      return this.form.get("childCount" + sysId).value
   }

  public getDayNumberOfDate(value: Date): string {
      return this.frameworkService.getDayNumberOfDate(value);
   }

  public getDayOfWeek(value: string) {
      return this.frameworkService.getDayOfWeekFromString(value);
   }

  public getExpansionClass(sysId) {
      if (this.isPresenceNotYetSet(sysId))
         return "panelInfo nullParticipation";
      if (this.isUserConnected && this.isPresent(sysId))
         return "panelInfo participation";
      if (this.isUserConnected && !this.isPresent(sysId))
         return "panelInfo noParticipation";
   }

  public getExpansionClassOnClosing(sysId) {
      if (this.isPresenceNotYetSet(sysId))
         return "panelInfo noParticipation";
      if (this.isUserConnected && this.isPresent(sysId))
         return "panelInfo participation";
      if (this.isUserConnected && !this.isPresent(sysId))
         return "panelInfo noParticipation";
   }

  public getHtml(value: string) {
      return this.frameworkService.replaceCrLfByBr(value ?? "");
   }

  public getImgURL(value: string) {
      if (value == null || value == "")
         return null;
      else
         return this.sanitizer.bypassSecurityTrustUrl(JSON.parse(value));
   }

  public getMemberPresenceCountActivity(activitySysId: number) {
      return this.memberPresenceCountActivity.get(activitySysId.toString());
   }

  public getMonthStringOfDate(value: Date): string {
      return this.frameworkService.getMonthStringOfDate(value);
   }

  public getParticularDemand(sysId) {
      return this.form.get("particularDemand" + sysId).value;
   }

  public getSeniorCount(sysId) {
      return this.form.get("seniorCount" + sysId).value
   }

  public getYearOfDate(value: Date): string {
      return this.frameworkService.getYearOfDate(value);
   }

  public isActivityUpdated(activitySysId: number) {
      if (this.isUpdateActivity.get(activitySysId.toString()) == undefined)
         return false;
      else {
         return this.isUpdateActivity.get(activitySysId.toString());
      }
   }

  public isAvailable(item: activity) {
      return this.frameworkService.getDateFormatYyyyMmDd(item.deadline) >= this.frameworkService.getDateFormatYyyyMmDd(this.frameworkService.getToday());
   }

  public isPresenceNotYetSet(activitySysId: number) {
      return this.isPresentActivity.get(activitySysId.toString()) == undefined;
   }

  public isPresent(activitySysId: number) {
      if (this.isPresentActivity.get(activitySysId.toString()) == undefined)
         return false;
      else {
         return this.isPresentActivity.get(activitySysId.toString());
      }
   }

   public isNotificationByEmailActivateForCurrentUser() {
      if (!this.accessRightsService.isUserConnected)
         return false;
      else
         return this.storageService.getUserConnected().isNotificationByEmailActivate && this.storageService.getUserConnected().isNotificationActivityReportByEmailActivate && this.accessRightsService.isUserConnectedInComitee();
   }

  public isStatShouldBeDisplayed(activitySysId: number) {
      return (this.isPresentActivity.get(activitySysId.toString()) != undefined);
   }

  public ngOnChanges(changes: SimpleChanges) {
      if (changes.isNetworkStatusOnline?.currentValue) {
         this.firstLoadPresenceValue();
      }
   }

  public ngOnDestroy(): void {
   }

  public ngOnInit() {
      this.firstLoadPresenceValue();
      this.subjectService.ActivityByMemberInsertedOrUpdatedSubject.subscribe(value => {
         if (value != null) {
            if (this.form.controls["seniorCount" + value.activitySysId] == undefined)
               this.form.addControl("seniorCount" + value.activitySysId.toString(), new FormControl(''));
            if (this.form.controls["childCount" + value.activitySysId] == undefined)
               this.form.addControl("childCount" + value.activitySysId.toString(), new FormControl(''));
            if (this.form.controls["particularDemand" + value.activitySysId] == undefined)
               this.form.addControl("particularDemand" + value.activitySysId.toString(), new FormControl(''));
            if (this.form.controls["isPresent" + value.activitySysId] == undefined)
               this.form.addControl("isPresent" + value.activitySysId.toString(), new FormControl(''));

            this.form.controls["seniorCount" + value.activitySysId].setValue(value.personSeniorCount);
            this.form.controls["childCount" + value.activitySysId].setValue(value.personChildCount);
            this.form.controls["particularDemand" + value.activitySysId].setValue(value.particularDemand);
            this.form.controls["isPresent" + value.activitySysId].setValue(value.isPresent >= 1);

            this.isUpdateActivity.set(value.activitySysId.toString(), false);
            this.isPresentActivity.set(value.activitySysId.toString(), value.isPresent >= 1);
         }
      });
   }

  public onChangeParticularDemand(activitySysId: number) {
      this.isUpdateActivity.set(activitySysId.toString(), true);
   }

  public onChangePersonCount(isSenior: boolean, activitySysId: number) {
      try {
         let field = isSenior ? "seniorCount" : "childCount";

         let value = this.form.get(field + activitySysId.toString()).value;

         let min = 0;
         if (isSenior) {
            if (this.getAge(this.storageService.getUserConnected().birthdayDate) >= 12) {
               min = 1;
            }
         }
         else {
            if (this.getAge(this.storageService.getUserConnected().birthdayDate) < 12) {
               min = 1;
            }
         }

         if (value >= min) {
            this.isUpdateActivity.set(activitySysId.toString(), true);
         }
         else {
            this.form.get(field + activitySysId.toString()).setValue(min);
         }
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }

  public onClickAddPerson(isSenior: boolean, activitySysId: number) {
      try {
         let field = isSenior ? "seniorCount" : "childCount";

         let value = this.form.get(field + activitySysId.toString()).value + 1;
         this.form.get(field + activitySysId.toString()).setValue(value);
         this.isUpdateActivity.set(activitySysId.toString(), true);
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }

  public async sendReportByEmail(activity: activity) {
      try {
         var userSysId = this.storageService.getUserConnected().sysId;

         await this.activityByMemberService.sendReportByEmail(activity.sysId, userSysId);
      }
       catch (err) {
            this.subjectService.IsButtonInLoadingSubject.next(false);
      }
  }

  public async onClickSaveReservationOfActivity(activity: activity) {
      try {
         await this.activityByMemberService.saveReservationOfActivity(
            new activityByMember(),
            activity,
            this.form.get("seniorCount" + activity.sysId).value,
            this.form.get("childCount" + activity.sysId).value,
            this.form.get("particularDemand" + activity.sysId).value,
            this.isPresent(activity.sysId));
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }

  public onClickSubstractPerson(isSenior: boolean, activitySysId: number) {
      try {
         let field = isSenior ? "seniorCount" : "childCount";

         let min = 0;
         if (isSenior) {
            if (this.getAge(this.storageService.getUserConnected().birthdayDate) >= 12) {
               min = 1;
            }
         }
         else {
            if (this.getAge(this.storageService.getUserConnected().birthdayDate) < 12) {
               min = 1;
            }
         }

         let value = this.form.get(field + activitySysId.toString()).value - 1;
         if (value >= min) {
            this.form.get(field + activitySysId.toString()).setValue(value);
            this.isUpdateActivity.set(activitySysId.toString(), true);
         }
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }

  public async onClickSwitchPresenceValue(activity: activity, value: boolean) {
      try {
         if (this.isAvailable(activity)) {
            let seniorCount: number = 0;
            let childCount: number = 0;

            if (this.getAge(this.storageService.getUserConnected().birthdayDate) < 12)
               childCount = 1;
            else
               seniorCount = 1;

            await this.activityByMemberService.saveReservationOfActivity(
               new activityByMember(),
               activity,
               seniorCount,
               childCount,
               "",
               value);
         }
         else
            this.subjectService.IsButtonInLoadingSubject.next(false);
      }
      catch (err) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      }
   }
}
