import { Component, HostListener, Inject, LOCALE_ID, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/sites/core/components/confirmDialog/confirmDialog.component';
import { enumActivityType, enumCacheStatusLoad, enumMatchDisplayStatus } from 'src/sites/core/enum/Enum';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { FrameworkService } from 'src/sites/core/services/framework.service';
import { PrintService } from 'src/sites/core/services/print.service';
import { match } from '../../models/match';
import { AppointmentService } from '../../services/appointmentService';
import { CacheService } from '../../services/cache.service';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
   selector: 'app-diary',
   templateUrl: './diary.component.html',
   styleUrls: ['./diary.component.scss'],
   encapsulation: ViewEncapsulation.None
})

export class DiaryComponent implements OnInit {
   public allMatchs: match[] = [];
   public displayedMatchColumns = [];
   public displayedMatchHeaderColumns = [];
   public filter = [];
   public form: FormGroup = new FormGroup({});
   public isDisplayOnAMobile: boolean = false;
   public isNetworkStatusOnline: boolean = false;
   public titleDescriptionDisplay: string = "";
   public allToggleLabel: string = "";
   public toggleTeamList = [];
   public allToggleTeamAreEnabled = true;

   constructor(
      @Inject(LOCALE_ID) private locale: string,
      public sanitizer: DomSanitizer,
      private frameworkService: FrameworkService,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private appointmentService: AppointmentService,
      private translateService: TranslateService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      private printService: PrintService,
      public dialog: MatDialog
   ) {
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });

      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });

      this.getScreenSize();

      this.displayedMatchColumns = ['day', 'teamSysId', 'timeOnSite', 'timeReserve', 'time', 'fromTeam'];
      this.displayedMatchHeaderColumns = ['Date', 'Equipe', 'Sur p.', 'Rés.', '1ère', 'Rencontre'];
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public classForMatchOfClub(match: match, column: string) {
      if (column == "fromTeam")
         return "matCell matCellDiary matCell100Percent";
      else
         return "matCell matCellDiary matCellNoWrap";
   }

   public displayColumn(column: string, match: match) {
      let label = "";
      switch (column) {
         case "time":
            label = match.time == "00:00" ? "N.C" : match.time.replace(":", "H");
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "timeReserve":
            label = match.type == enumActivityType[enumActivityType.HAINAUT_CUP] || match.type == enumActivityType[enumActivityType.YOUNG] ? "" : (match.time == "00:00" ? "N.C" : this.frameworkService.getMatchTimeOfReserve(match));
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "timeOnSite":
            label = match.time == "00:00" ? "N.C" : this.frameworkService.getMatchTimeOnSite(match);
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "teamSysId":
            label = match.team?.teamCategory;
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "day":
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               label = "<span class='BYE'>" + this.frameworkService.getDateWithDayFormatForScreen(match.day.toString()) + "</span>";
            else
               label = (match.day == null) ? "N.C" : this.frameworkService.getDateWithDayFormatForScreen(match.day.toString());
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
         case "fromTeam":
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>BYE</span>";

            let temp: string = "";
            if (match.type != enumActivityType[enumActivityType.YOUNG]) {
               if (match.type == enumActivityType[enumActivityType.CHAMPIONSHIP] && match.number.startsWith("CL"))
                  temp += this.translateService.instant(match.type) + " " + this.translateService.instant("CHAMPIONSHIP_FINAL") + "<br>";
            }
            if (match.type == enumActivityType[enumActivityType.HAINAUT_CUP]) {
               temp += this.translateService.instant("HAINAUT_CUP") + "<br>";
            }

            let icon = match.isAtHome ? "<span class='material-icons matIconColorForPrint matIconVAB'>house</span> " : "";
            if (match.isAtHome != null) {
               if (match.isAtHome)
                  temp += "<span class='VAB'>" + icon + " <span style='white-space:normal'>" + match.awayTeam + "</span></span>";
               else
                  temp += " <span style='white-space:normal'>" + match.fromTeam + "</span>";
            }
            else
               temp += " <span style='white-space:normal'>" + match.fromTeam + " - " + match.awayTeam + "</span>";
            return temp;
         default:
            label = match[column];
            if (match.fromTeam.includes(this.frameworkService.getByeStatus()) || match.awayTeam.includes(this.frameworkService.getByeStatus()))
               return "<span class='BYE'>" + label + "</span>";
            else
               return label;
      }
   }

   public getColumnClass(column: string, match: match) {
      if (column == "teamSysId")
         return "fontSize " + (match.team?.class);
      else
         return "";
   }

   public getPreviousMatchDay(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.allMatchs[i - 1]?.day;
      }
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public loadAllMatchOfTeams() {
      let selectedTeamsSysId = [];
      this.toggleTeamList.forEach(element => {
         if (!element.enabled) selectedTeamsSysId.push(element.sysId);
      });

      this.allMatchs = this.cacheService.getAllMatchs().filter(m => selectedTeamsSysId.Contains(m.teamSysId) && m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.AJO] &&
         (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
            m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName())))
         .sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type < this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
   }

   public onFilterChange(): void {
      this.setAllToggleTeamAreEnabled();
      if (this.allToggleTeamAreEnabled)
         this.allToggleLabel = "Toute";
      else
         this.allToggleLabel = "Aucune";
      this.loadAllMatchOfTeams(); 
   }

   public onPrintDiary() {
      this.printService.onDataReady();
      this.printService.printDocument('diary');
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public onSendToMail() {
      this.subjectService.IsButtonInLoadingSubject.next(false);

      let message1 = "Les rencontres sélectionnées vous seront envoyées par mail. Vous pourrez ensuite les importer dans votre agenda personnel. Si vous ne désirez que les rencontres de votre équipe, sélectionnez la préalablement.";

      const message2 = "Voulez-vous recevoir le mail ?";

      const dialogData = new ConfirmDialogModel("Calendrier des rencontres", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            let appServiceArray: Observable<any>[] = [];

            let allTeams = this.toggleTeamList.ToArray().Where(i => !i.enabled).ToArray().map(x => (
                  Number(x.sysId)
               )
            );

            appServiceArray.push(this.appointmentService.sendMatchAppointment(allTeams, this.storageService.getUserConnected().email));

            forkJoin(appServiceArray).subscribe(res => {
               this.subjectService.IsButtonInLoadingSubject.next(false);
               if (res[0])
                  this.frameworkService.displayAlert(true, "Les rencontres ont été envoyées sur votre email " + this.storageService.getUserConnected().email + ".");
               else
                  this.frameworkService.displayAlert(false, "Les rencontres n'ont pas pu être envoyées sur votre email " + this.storageService.getUserConnected().email + " en raison d'une erreur technique.");
            });
         }
         else
            this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

   onTeamToggleChanged(event: MatSlideToggleChange, item: any) {
      item.enabled = event.checked;
      let isAllEnabled: boolean = false;
      this.toggleTeamList.forEach(element => {
         if (element.enabled) isAllEnabled = true;
      });
      this.allToggleTeamAreEnabled = isAllEnabled;
      this.onFilterChange();
   }

   setAllToggleTeamAreEnabled() {
      let isAllEnabled: boolean = false;
      this.toggleTeamList.forEach(element => {
         if (element.enabled) isAllEnabled = true;
      });
      this.allToggleTeamAreEnabled = isAllEnabled;
   }

   onToggleAllTeams(event: any) {
      this.toggleTeamList.forEach(item => item.enabled = event.checked);
      
      this.onFilterChange();
   }

   public refresh() {
      this.allToggleTeamAreEnabled = false;

      this.toggleTeamList = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.division != "" && i.sysId > 1).OrderBy(i => i.teamDisplay).ToArray().map(x => (
         {
            name: x.teamCategory,
            sysId: x.sysId,
            enabled: false,
            class: x.class
         })
      );
      this.onFilterChange();
   }

   public translatePage() {
   }
}